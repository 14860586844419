export const RO_TRANSLATION = {
    header: {
        headerLink: {
            aboutUs: {
                name: 'Despre Noi'
            },
            prices: {
                name: 'Prețuri'
            },
            affiliateProgram: {
                name: 'Program de afiliere'
            },
            howToThriveInEcomm: {
                name: 'Cum să prosperi în comerțul online'
            }
        },
        signIn: "Conectare",
        language: "Language",
    },

    footer: {
        greenTitle: 'Bucură-te de 14 zile perioadǎ de probǎ!',
        whiteTitle: 'Nu este nevoie de card de credit',
        subtitle: 'Deschide-ți magazinul online și începe vânzările astăzi!',
        textBtn: 'ÎNCEPE ACUM',
        freeTrial: 'Încercare gratuită',
        rightsReserved: 'Toate drepturile rezervate 2024 | © Sellavi',
        footerLink: {
            title1: 'Despre Noi',
            title2: 'Prețuri',
            title3: 'Program de afiliere',
            title4: 'Cum să prosperi în comerțul electronic',
            mobileTitle4: 'Cum să prosperi în comerțul electronic',
        },
        mail: 'info@sellavi.com',
    },

    blogArticle: {
        content: 'Conținut',
        bannerTitleFirstLine: 'ÎNCEPE SĂ VINZI',
        bannerTitleSecondLine: 'CU SELLAVI',
        bannerBtnText: 'CHIAR ACUM!',
        greenLineText: 'Dacă ai întrebări, echipa noastră de suport calificată este întotdeauna fericită să te ajute.',
        like: 'Like?',
        readTime: 'Timp de citire',
        shareSocial: 'Share pe social media',
        min: 'minute'
    },
    popupSignUp: {
        back: 'Înapoi',
        signupTitle: 'Încearcă gratuit timp de 14 zile',
        signupText: {
            preambulaTermsService: 'Prin crearea unui cont, ești de acord cu',
            termsService: 'Termenii și Condițiile ↗',
            preambulaPrivacyPolicy: 'noastre și ai citit și înțeles',
            privacyPolicy: 'Politica de Confidențialitate ↗'
        },
        inputEmail: 'E-mailul tău',
        inputPass: 'Parola',
        submitBtn: 'Creează cont'
    },
    main: {
        mainBanner: {
            bannerTitle: 'Magazinul tău online este la doar 5 minute distanțǎ!',
            bannerPlaceholder: 'Adresa de e-mail',
            bannerBtn: 'Probează gratuit'
        },
        mainNav: {
            navLink: {
                start: {
                    name: 'Începe perioada de probă'
                },
                reviews: {
                    name: 'Recenziile clienților'
                },
                steps: {
                    name: 'Pașii de configurare'
                },
                features: {
                    name: 'Funcții'
                },
                ai: {
                    name: 'Comerț AI'
                },
                examples: {
                    name: 'Exemple'
                },
                benefits: {
                    name: 'Beneficii'
                },
            },
            navBtn: 'Probează gratuit! '
        },
        mainReviews: {
            revFirst: {
                name: 'Anna',
                description: `Am optat pentru Sellavi datorită fiabilității și securității platformei. Întreținerea magazinului era cea mai mare provocare pentru noi, dar Sellavi a făcut ca procesul să fie ușor și fără probleme. Sprijinul pe care l-am primit din partea echipei în timpul înființării magazinului online ne-a lăsat o impresie de neuitat. Răbdarea și angajamentul remarcabil al echipei Sellavi ies cu adevărat în evidență, iar rezultatul reflectă calitatea excepțională a serviciilor lor.`,
            },
            revSecond: {
                name: 'Isabelle',
                description: `Am ales Sellavi pentru magazinul meu online pentru că mi-au ascultat cu adevărat ideile.

                Am trimis o cerere, am vorbit cu un manager despre nevoile mele, am plătit pentru planul Sellavi Plus și, în câteva săptămâni, aveam un site web gata făcut, care reflecta perfect brandul meu. Designul vizual și funcționalitatea, în special pentru produsele mele personalizabile, au fost de top. Sunt client de ceva timp și nu am de gând să schimb.`,
            },
            revThird: {
                name: 'Nia Nikolova',
                description: `Interacțiunea cu echipa Sellavi este eficientă și plăcută; ei ne oferă instrucțiuni esențiale pentru fluxul de lucru și ne ajută să dăm viață dorințelor și viziunii noastre. Lucrul pe platformă este incredibil de ușor datorită interfeței intuitive. Cu un efort minim, obținem rezultate remarcabile.`,
            },
            revFour: {
                name: 'Klementina Mileva',
                description: `Să aleg Sellavi pentru a-mi crea magazinul online a fost o adevărată joacă de copii. Interfața prietenoasă a platformei a făcut ca întregul proces să fie ușor și rapid. Nu numai atât, dar rezultatul final mi-a depășit așteptările - magazinul arată uimitor! Magazinul meu online a primit recenzii uimitoare din partea clienților imediat după lansare!`,
            },
            revFive: {
                name: 'Tоdor Angelov',
                description: `Am ales Sellavi pentru magazinul meu online pentru că oferă tot ceea ce mi-am dorit de la o platformă de creare de site-uri web: o gamă variată de teme adaptate pentru diferite tipuri de magazine, un design elegant și curat, o integrare perfectă cu Google Analytics, listări automate pe Facebook și cataloagele Instagram și instrumente fiabile de analiză a vânzărilor. A fost cea mai bună decizie - magazinul meu este mai prosper ca niciodată!`,
            },
            revSix: {
                name: 'Borislava Georgieva',
                description: `Am dat peste Sellavi când eram în căutarea platformei perfecte pentru a construi magazinul online al brandului meu. Ceea ce m-a impresionat a fost ușurința și simplitatea de utilizare a platformei, precum și răspunsul rapid și dedicarea echipei. Sunt absolut încântată de produsul final și am primit o tonă de complimente de la clienți și prieteni.`,
            },
            revSeven: {
                name: 'Altinbas',
                description: `Platforma Sellavi funcționează impecabil! Îmi place în mod deosebit secțiunea de produse; este ușor de utilizat, iar rezultatul de pe site arată uimitor. Clienții noștri își aleg fără efort articolele dorite și trec direct la plasarea comenzilor, reducând astfel necesitatea de a solicita informații prin intermediul formularelor de contact. Sincer, colaborarea cu Sellavi este tot ce poți face mai bun pentru afacerea ta.`,
            },
            revEight: {
                name: 'Miglena',
                description: `Mi-am creat magazinul online folosind platforma Sellavi și pot spune cu încredere că sunt absolut încântată de tot ceea ce oferă: comunicarea cu echipa, diversitatea funcțiilor și ușurința de utilizare a platformei în sine. Recomand din toată inima această platformă tuturor celor care caută o soluție simplă și eficientă pentru crearea unui magazin online.`,
            },
            revNine: {
                name: 'Kremena Ivanova-Milusheva',
                description: `Să descopăr Sellavi în timp ce căutam o platformă pentru magazinul nostru online a fost o adevărată fericire! Experimentasem diverse opțiuni înainte și eram aproape gata să renunț, dar apoi am găsit această platformă. Sellavi a răspuns pozitiv la toate întrebările și solicitările mele, dând dovadă de răbdare și empatie. Fiecare membru al echipei care a contribuit la magazinul nostru posedă o personalitate distinctă și o abordare centrată pe client, care se asigură că fiecare client se simte apreciat!`,
            },
            revTen: {
                name: 'Gabriella',
                description: `Ceea ce m-a impresionat cu adevărat la platforma Sellavi este varietatea largă de funcționalități și extensii. Clienții mei sunt mai fericiți ca niciodată: feedback-ul lor este extrem de pozitiv și le este foarte ușor să lucreze cu magazinul. Acest lucru este ceea ce contează cel mai mult pentru mine!`,
            }
        },
        mainSteps: {
            step1Text: "Alege un șablon",
            step2Text: "Încarcă-ți produsele",
            step3Text: "Vinde cǎtre oricine, oriunde",
            step4Text: "Urmărește și analizează-ți profiturile",
            step: 'Pasul'
        },
        mainMobileFirst: {
            title: 'Abordare centrată pe mobil',
            text: `Sellavi este construit pentru comerțul mobil. Oferim gratuit optimizare mobilă avansată, asigurându-te că magazinul tău funcționează perfect și arată uimitor pe toate dispozitivele portabile.`
        },
        mainFeatures: {
            titleFirstLine: `Un magazin care are`,
            titleSecondLine: `de toate`,
            text: 'Gestionează-ți magazinul dintr-un singur loc: tot ce ai nevoie se află deja în panoul de administrare',
            iconText: {
                text1: 'Hosting nelimitat',
                text2: 'Template-uri personalizabile',
                text3: 'CRM avansat',
                text4: 'SSL gratuit',
                text5: 'SEO încorporat',
                text6: 'Monede multiple',
                text7: 'Limbi multiple',
                text8: 'Panou de administrare cu mai mulți utilizatori',
                text9: 'Plăți online integrate',
                text10: 'Apple Pay și Google Pay',
                text11: 'Managementul facturilor',
                text12: 'Rapoarte avansate',
                text13: 'Sistem avansat de promovare',
                text14: 'Notificări prin SMS',
                text15: 'API-ul Facebook Pixel',
                text16: 'API-ul Google Analytics',
                text17: 'Managementul coșurilor abandonate',
                text18: 'Grupuri de clienți',
                text19: 'Gestionarea abonamentului',
                text20: 'Rezervare',
                text21: 'Recenzii și evaluări ale produselor',
                text22: 'Execuția comenzilor',
                text23: 'Generator de cupoane',
                text24: 'Blog',
                text25: 'Management avansat al livrărilor',
                text26: 'Editori JS și CSS',
                text27: 'Produse digitale',
                text28: 'Personalizare checkout',
                text29: 'API bogat cu Webhooks',
                text30: 'Tabloul de bord Analytics',
                text31: 'Generator modular de pagini',
                text32: 'Conexiune Google ADS',
                text33: 'Oferte exclusive de la parteneri',
                text34: 'Instagram Shopping și Facebook Catalog',
                text35: 'Google Shopping',
                text36: 'Magazin TikTok',
                text37: 'Produse complementare',
                text38: 'Pagini de vânzări',
                text39: 'Bannere video și de imagine',
                text40: 'Filtre de produse și sortare',
                text41: 'Google Rich Snippets',
                text42: 'Marketing prin e-mail',
            },
            textBtn: 'Probează timp de 14 zile! '
        },
        mainCreateAi: {
            titleFirstLine: 'Creează-ți magazinul cu puterea',
            discriptionBeforeBtn: 'Pur și simplu încarcă fotografia produsului tău și poți vedea cum prototipul magazinului tǎu online prinde viață',
            textBtn: 'Încarcǎ poza produsului tău',
            orangeTextBtn: 'vezi magia',
            loading: 'Loading...',
            startTrialTextBtn: 'Probează gratuit!',
            placeholderEmail: 'Adresa de e-mail',
            facebook: {
                textAfterCard: 'Campania ta pe Facebook este în funcțiune'
            },
            instagram: {
                textAfterCard: 'Catalogul tău Instagram este plin de produse'
            },
            pay: {
                textAfterCard: 'Produsele tale sunt atât de ușor de cumpărat acum, încât poți accepta plăți online în întreaga lume.'
            },
            adaptive: {
                textAfterCard: 'Conținutul tău se adaptează instantaneu la orice dimensiune a e'
            },
            google: {
                textAfterCard: 'Puteți vinde chiar în Google Shopping'
            }
        },
        mainOperates: {
            title: `Operăm în România,
             ca tu sa te poți extinde global`,
        },
        mainMagnati: {
            title: 'este partenerul nostru de încredere ',
            text: 'pentru nevoile financiare ale afacerii tale',
            animateText: {
                first: 'Verificare persoane juridice',
                second: 'Semnarea documentelor de la distanță',
                third: 'Servicii bancare online',
                forth: 'ATM-uri cu funcție de depozit',
                five: 'Consultare cu experți',
                six: 'Servicii de brokeraj',
                seven: 'Consultanta juridica firme',
                eight: 'Administrare',
                nine: 'Software de facturare'
            }
        },
        mainJoin: {
            title: 'Alăturați-vă miilor de afaceri care prosperă deja cu Sellavi',
            joinCards: {
                column1: {
                    title1: 'Magazin de bijuterii handmande',
                    title2: 'Magazin de huse',
                    title3: 'Magazin de bijuterii',
                    title4: 'Florarie',
                    title5: 'Magazin de șosete elegante',
                },
                column2: {
                    title1: 'Magazin de articole pentru casă',
                    title2: 'Magazin de haine',
                    title3: 'Magazin de cărți poștale digitale',
                    title4: 'Magazin de haine',
                    title5: 'Companie de conserve din aluminiu',
                    title6: 'Candelabre și lumini de noapte',
                },
                column3: {
                    title1: 'Magazin de mobila',
                    title2: 'Numerolog',
                    title3: 'Magazin de figurine si carti de joc',
                    title4: 'Magazin de haine',
                    title5: 'Servicii de curățenie',
                },
                column4: {
                    title1: 'Magazin de produse chimice pentru uz casnic',
                    title2: 'Magazin de haine',
                    title3: 'Magazin pentru marinari',
                    title4: 'Magazin de accesorii personalizate',
                    title5: 'Magazin de haine',
                },
                column5: {
                    title1: 'Magazin de bijuterii',
                    title2: `Magazin de îmbrăcăminte pentru copii`,
                    title3: 'Magazin de animale',
                    title4: 'Magazin de alimente sanatoase',
                    title5: 'Magazin de bijuterii',
                }
            }
        },
        mainSayAboutUs: {
            title: 'Dezvoltă-ți afacerea cu Sellavi',
            subtitle: 'Iată ce spun despre noi clienții noștri de succes',
            cardFirstTitle: 'Mi-am crescut vânzările cu 70% datorită lui Sellavi',
            cardSecondTitle: 'Totul pentru buna funcționare a afacerii este deja aici',
            cardThirdTitle: 'Într-o săptămână, eram gata pregătiți să vindem',
            cardFirstName: 'Klementina Mileva',
            cardSecondName: 'Altinbas',
            cardThirdName: 'DB Prive'
        },
        mainLogistic: {
            titleFirstLine: `Soluții logistice `,
            titleSecondLine: 'integrate',
            subtitle: 'Servicii de livrare la nivel global, depozite și soluții de aprovizionare - toate într-un singur loc',
        },
        mainMarketing: {
            titleFirstLine: `Vinde cǎtre oricine,`,
            titleSecondLine: `oriunde`,
            subtitle: 'Canale de marketing diversificate pentru a crește vânzările și a ajunge la public',
        }
    },
    aboutUs: {
        mainTitleNumber: 'Peste 250 000+',
        mainTitle: 'magazine online prosperă cu Sellavi',
        mainText: `Bine ai venit la Sellavi, o platformă globală de comerț electronic orientată către client. Ne aflăm aici pentru a elibera orice tip de afacere de agitația nesfârșită cu ajutorul magazinelor online. Acordăm prioritate comercianților noștri, asigurându-le o experiență fără probleme și fără probleme în lumea comerțului electronic. Ia-ți la revedere de la provocările constante și lasă-ne să simplificăm și să optimizăm călătoria afacerii tale online.`,
        mainTextLastLine: 'Îmbrățișează ușurința, îmbrățișează Sellavi.',
        successNumbers: {
            title: 'Succesul în cifre',
            conversionRate: 'Rata de conversie',
            monthlyVisitors: 'Vizitatori lunari',
            averageTransaction: 'Tranzacție medie',
            transactionsPerDay: 'Tranzacții pe zi',
            productsStorage: 'Produse în depozit',
            million: "milion",
            over: "peste"
        },
        ourStory: {
            title: 'Povestea noastră',
            textFirstPart: `Călătoria noastră a început în 2015, cu viziunea de a revoluționa comerțul electronic. Am reunit o echipă internațională de experți ultramoderni pentru a dezvolta o soluție care să schimbe regulile jocului - un serviciu unic care să transforme afacerile online din întreaga lume. Astfel, s-a născut Sellavi.`,
            textSecondPart: `Astăzi, operăm cu mândrie în întreaga lume, deservind peste 250.000 de clienți la nivel global. Platforma noastră open SaaS facilitează afacerile de toate mărimile, oferind instrumente esențiale pentru a putea începe, administra și extinde toate acestea fără efort. Nu este vorba doar despre creșterea afacerii - este vorba despre crearea experiențelor de shopping de neuitat pentru clienții tăi. Alătură-te pentru a redefini împreună viitorul comerțului electronic.`
        },
        mission: {
            title: 'Misiunea Noastrǎ',
            text1: 'Ajutam întreprinderile de toate dimensiunile să vândă fără efort',
            text2: 'Oferim o creștere confortabilă și rapidă',
            text3: 'Oferim tot ceea ce are nevoie o afacere pentru succes, rapid și la fața locului',
            text4: 'Automatizăm procesele tehnice și să optimizăm gestionarea afacerii',
        },
        bestEcomm: {
            titleFirstLine: 'Cea mai bună',
            titleSecondLine: 'tehnologie e-commerce'
        },
        globalBlock: {
            title: 'Cei care au început să vândă cu noi la nivel local au acum succes la nivel global.',
            textLeft: 'Sellavi lucrează în întreaga lume',
            textRight: 'Cu noi, puteți să vă extindeți prezența și să ajungeți la un public mai larg zi de zi.'
        },
        departments: {
            title: 'Departamente',
            joinBtn: 'Alătură-te echipei',
            cards: {
                card1: 'Cercetare și dezvoltare',
                card2: 'Asigurarea calității',
                card3: 'Suport',
                card4: 'Specialiști e-commerce',
                card5: 'Marketing și design',
                card6: 'Parteneriat',
                card7: 'Departamentul juridic',
                card8: 'Securitate cibernetică',
                card9: 'Logistică'
            }
        },
        ourPartners: {
            title: 'Partenerii noștri'
        }
    },
    prices: {
        choosePlan: {
            title: 'Alege-ți planul',
            trialBtn: 'Probează gratuit! ',
            pricePer: 'lunar',
            standartPlan: {
                priceNumber: 101,
                priceCurrency: 'LEI',
                description: 'Un magazin online cu condiții favorabile pentru vânzări: soluții de plată online, integrare cu instrumente de publicitate, soluții logistice, social media, analiză avansată și suport live.',
                keyPoints: {
                    point1: 'Produse nelimitate',
                    point2: 'Spațiu de stocare nelimitat',
                    point3: 'Lățime de bandă nelimitată',
                    point4: 'Plăți online',
                    point5: 'Fără taxe suplimentare de tranzacționare',
                    point6: 'Tarife de livrare în timp real',
                    point7: 'Template-uri de Design',
                    point8: 'Canale de vânzări: Facebook, Instagram, Google, TikTok, WhatsApp',
                    point9: 'Managementul produselor și al comenzilor',
                    point10: 'Sistem CRM',
                    point11: 'Prețuri și reduceri en-gros',
                    point12: 'Promoții și cupoane',
                    point13: 'Valute multiple',
                    point14: 'Limbi multiple',
                    point15: 'Panou de administrare cu mai mulți utilizatori',
                    point16: 'Tabloul de bord în timp real',
                    point17: 'Îți conectezi propriul domeniu',
                    point18: 'SSL Gratuit',
                    point19: 'Instrumente analitice',
                },
                keyPointStar: 'Când este achiziționat pentru 3 ani',
                keyPointLast: 'Toate actualizările viitoare sunt gratuite!'
            },
            plusPlan: {
                priceNumber: 455,
                priceCurrency: 'LEI',
                description: `Îmbunățește-ți posibilitățile afacerii tale cu un specialist personal în comerț electronic, un design personalizat și un manager de asistență personală.',
                `,
                keyPointsPlus: {
                    title: 'Totul în Sellavi, plus:',
                    point1: 'Specialist personal e-commerce',
                    point2: 'Design individual al magazinului',
                    point3: 'Asistență live pe mai multe canale',
                }
            }
        },
        features: {
            title: 'Comparǎ caracteristicile planului',
            textBtnCreateAcc: 'Creează cont',
            textBtnTrial: 'Probează gratuit 14 zile!',
            sales: {
                title: 'Canale de vânzări',
                items: {
                    title1: 'Magazin online',
                    title2: 'Instagram Shop',
                    title3: 'Facebook Shop',
                    title4: 'TikTok Shop',
                    title5: 'Google Shopping',
                    title6: 'WhatsApp',
                    title7: 'SMS',
                    title8: 'Newsletters'
                }
            },
            onlineStore: {
                title: 'Magazin online',
                items: {
                    title1: 'Gestionarea comenzilor',
                    title2: 'Management de produs',
                    title3: 'Urmărirea inventarului',
                    title4: 'Managementul clientilor',
                    title5: 'Gestionarea rezervărilor',
                    title6: 'Managementul recenziilor produselor',
                    title7: 'Template-uri de Design',
                    title8: 'Magazin online în mai multe limbi',
                    title9: 'Conturi personale pentru clienți',
                    title10: 'Cumpără/Conectează propriul domeniu',
                    title11: 'Certificat SSL gratuit',
                    title12: 'Opțiuni de sortare și filtrare a produselor',
                    title13: 'Cărucior persistent',
                    title14: 'Finalizare pe o singură pagină',
                    title15: 'Checkout pentru oaspeți',
                    title16: 'Câmpuri personalizate la finalizare',
                    title17: 'Modul de restricție de cumpărare',
                    title18: 'Conformitatea GDPR'
                }
            },
            admin: {
                title: 'Panoul Administrativ',
                items: {
                    title1: 'Tabloul de bord în timp real',
                    title2: 'Extensiile magazinului',
                    title3: 'Panou de administrare în mai multe limbi',
                    title4: 'Panou de administrare pentru mai mulți utilizatori',
                    title5: 'Roluri de user cu peste 100 de permisiuni',
                    title6: 'Modul de întreținere',
                    title7: "Acces la API-ul Sellavi",
                    title8: 'Acces la Webhooks'
                }
            },
            productPrice: {
                title: 'Produse, prețuri și inventar',
                items: {
                    title1: 'Produse nelimitate',
                    title2: 'Categorii nelimitate',
                    title3: 'Stocare media nelimitată',
                    title4: 'Produse digitale',
                    title5: 'Urmărire avansată a inventarului',
                    title6: 'Produse complementare',
                    title7: 'Produse recomandate',
                    title8: 'Evaluare și recenzii ale produselor',
                    title9: 'Produse ascunse',
                    title10: 'Categoriile ascunse',
                    title11: 'Preturi ascunse',
                    title12: 'Reduceri programate',
                    title13: 'Preturi angro',
                    title14: 'Magazin online cu mai multe valute',
                    title15: 'Opțiuni de produs',
                    title16: 'Filtre de produs',
                    title17: 'Stickere',
                    title18: 'Branduri',
                    title19: 'Import / Export',
                    title20: 'Actualizare în masă'
                }
            },
            shipping: {
                title: "Expediere și Livrare",
                items: {
                    title1: 'Servicii de expediere încorporate',
                    title2: 'Transport international',
                    title3: 'Tarife de livrare în timp real',
                    title4: 'Metode de livrare personalizate',
                    title5: 'Livrare condiționată',
                    title6: 'Livrare zonală',
                    title7: 'Livrări, legate de produse',
                    title8: 'Comandǎ rapidă de livrare din interfață',
                    title9: 'Sistem de urmărire a transportului încorporat',
                    title10: 'Execuția comenzilor',
                    title11: 'Depozite',
                    title12: 'Puncte de preluare'
                }
            },
            payments: {
                title: 'Plăți',
                items: {
                    title1: 'Soluție de plată online',
                    title2: 'Apple Pay și Google Pay',
                    title3: 'Configurare vPOS gratuită',
                    title4: 'Tarif special pentru comisioane de tranzacție',
                    title5: 'Facturare online',
                    title6: 'Plata la livrare',
                    title7: 'Transfer bancar',
                    title8: 'Plăți recurente',
                    title9: 'Câmpuri personalizate la finalizare'
                }
            },
            design: {
                title: "Design",
                items: {
                    title1: 'Varietate de șabloane de design ',
                    title2: 'Generator modular de pagini',
                    title3: 'Editori CSS/JS',
                    title4: 'Bannere video',
                    title5: 'Instrument de management al conținutului',
                    title6: 'Opțiuni de styling rapide',
                    title7: 'Setări pentru afișajul mobil',
                    title8: 'Design personalizat magazin',
                }
            },
            orders: {
                title: 'Comenzi',
                items: {
                    title1: 'Comenzi nelimitate',
                    title2: 'Gestionarea comenzilor directe',
                    title3: 'Fără taxe suplimentare',
                    title4: 'Crearea manuală a comenzii',
                    title5: 'Editor de comenzi',
                    title6: 'Starea comenzilor',
                    title7: 'Generator digital de facturi',
                    title8: 'Notificări despre comenzi noi prin e-mail, SMS și Telegram',
                    title9: 'Gestionarea rezervărilor',
                    title10: 'Export al comenzilor',
                    title11: 'Durată de viață a coșului abandonat de 30 de zile'
                }
            },
            clients: {
                title: 'Clienții',
                items: {
                    title1: 'Sistem de management',
                    title2: 'Istoricul cumparaturilor',
                    title3: 'Conturi personale pentru clienți',
                    title4: 'Repetare rapidă a comenzii',
                    title5: 'Mai multe adrese salvate',
                    title6: 'Grupuri de clienți',
                    title7: 'Experiență de cumpărare personalizată',
                    title8: 'Câmpuri customizate',
                    title9: 'Import / Export',
                    title10: 'Actualizare în masă'
                }
            },
            marketing: {
                title: 'Marketing și reclame',
                items: {
                    title1: 'SEO încorporat',
                    title2: 'Google Ads',
                    title3: 'Facebook Pixel',
                    title4: 'API-ul Facebook Conversions',
                    title5: 'Scenarii promotionale',
                    title6: 'Reduceri și coduri promoționale',
                    title7: 'Generator de coduri promoționale',
                    title8: 'Vouchere cadou',
                    title9: 'Pagini de vânzări',
                    title10: 'Blog încorporat',
                    title11: 'Categoria automată „Vânzare”.',
                    title12: 'Editare meta-tags SEO',
                    title13: 'Editor Robots.txt',
                    title14: 'Sitemap.xml generat automat',
                    title15: 'Gestionarea redirecționărilor 301'
                }
            },
            analitics: {
                title: 'Analytics',
                items: {
                    title1: 'Tabloul de bord în timp real',
                    title2: 'Rapoarte despre coșurile abandonate',
                    title3: 'Rapoarte de utilizare a codului promoțional',
                    title4: 'Google Analytics GA4',
                    title5: 'API-ul Google Analytics',
                    title6: 'Google Tag Manager'
                }
            },
            security: {
                title: 'Securitate',
                items: {
                    title1: 'Firewall încorporat',
                    title2: 'PCI DSS Nivel 1',
                    title3: 'Certificat SSL',
                    title4: 'Protecție DDoS',
                    title5: 'Protectie spam',
                    title6: 'Monitorizare magazin online 24/7',
                    title7: 'Conformitatea GDPR'
                }
            },
            communication: {
                title: 'Comunicații',
                items: {
                    title1: 'Widget WhatsApp',
                    title2: 'Widget Facebook',
                    title3: 'Template-uri de mesaje',
                    title4: 'Date dinamice personalizate ale clienților',
                    title5: 'Notificări de actualizare a stării comenzii',
                    title6: 'Notificări prin SMS',
                    title7: 'Notificări prin email',
                    title8: 'Conexiune instrumente de marketing prin e-mail',
                    title9: 'Conexiune personalizată cu widget-uri'
                }
            },
            serviceSupport: {
                title: 'Service și Suport',
                items: {
                    title1: 'Baza mare de cunostințe',
                    title2: 'Asistență prin chat live',
                    title3: 'Asistență prin e-mail',
                    title4: 'Suport multicanal',
                    title5: 'Specialist personal e-commerce',
                    title6: 'Asistență rapidă în prima linie'
                }
            }
        },
        faq: {
            title: 'Întrebări frecvente',
            text: 'Tot ce trebuie să știi despre platformă și plăți',
            questionAnswer: {
                question1: 'Trebuie să fiu designer sau dezvoltator pentru a folosi Sellavi?',
                answer1: `Categoric nu! Interfața intuitivă a Sellavi îți permite să îți personalizezi magazinul online în câteva minute, chiar și fără cunoștințe de programare. Acest lucru înseamnă că te poți concentra pe ceea ce contează cel mai mult - produsele și clienții tăi.

                Ai nevoie de o mână de ajutor? Baza noastră cuprinzătoare de cunoștințe oferă răspunsuri la majoritatea întrebărilor, iar echipa noastră prietenoasă de asistență tehnică este întotdeauna disponibilă prin intermediul pictogramei verzi de chat din colțul din dreapta jos al magazinului tău.` ,
                question2: 'Îmi pot migra magazinul către Sellavi?',
                answer2: `Sigur că da! Echipa noastră de experți tehnici este întotdeauna pregătită și echipată pentru a te ajuta să îți muți magazinul online pe platforma noastră. Tot ceea ce avem nevoie de la tine este tabelul de export al produselor și al clienților, iar migrarea ta va fi gata în cel mai scurt timp! Nu îți face griji, nu vei pierde niciun fel de date în timpul acestui proces.
                `,
                question3: 'Cum pot face upgrade planului meu?',
                answer3: `Este ușor să îți actualizezi planul Sellavi! Urmează doar acești 4 pași:

                1) Conectează-te la panoul tău de administrare.
                2) Apasă pe numărul magazinului tău aflat în colțul din stânga jos.
                3) Alege „Gestionează-ți planul” din meniu. Acest lucru va deschide opțiunile planului tău.
                4) Alege planul* la care dorești să faci upgrade și confirmă-ți selecția.
                
                Asta este tot!  Planul tău va fi upgradat imediat.
                
                *Poți, de asemenea, să combini planurile noastre după bunul tău plac. Contactează echipa de asistență pentru a afla mai multe!`,
                question4: 'Pot folosi propriul meu domeniu?',
                answer4: `Bineînțeles că da! Poți adăuga un domeniu existent la magazinul tău online - indiferent de planul pe care îl alegi.'
                `,
                question5: 'Îmi pot gestiona magazinul de pe un dispozitiv mobil?',
                answer5: `Da! Platforma Sellavi este orientată în primul rând către mobil, asigurând confort atât pentru clienți, cât și pentru comercianți. Designul responsive se concentrează pe versiunea mobilă, îmbunătățind experiența de cumpărare și stimulând vânzările.
                `,
                question6: 'Vand o cantitate mare de produse. Există o limită pentru găzduire și numărul de produse care pot fi încărcate în magazin?',
                answer6: 'Sellavi se laudă cu găzduire nelimitată și permite un număr nerestricționat de încărcări de produse pentru fiecare magazin. Indiferent dacă vindeți o mână sau o gamă extinsă de produse, platforma noastră vă acoperă!',
                question7: 'Există instrumente analitice integrate pentru a urmări procesele comerciale?',
                answer7: 'Absolut! Sellavi oferă un tablou de bord intuitiv care afișează venituri în timp real, rapoarte de vânzări și statistici esențiale pentru a te ține informat despre afacerea ta.',
                question8: 'Trebuie să plătesc pentru traficul pe care îl generez și pentru stocarea datelor?',
                answer8: 'Nu percepem bani pentru traficul pe care îl generezi. De asemenea, indiferent de planul pe care îl ai, poți descărca și stoca gratuit o cantitate nelimitată de informații și date.',
                question9: 'Sunt organizațiile non-profit eligibile pentru reduceri?',
                answer9: `Da, organizațiile non-profit pot primi o reducere. Te rugăm să ne trimiteți un e-mail la <a class="faq__item-info-answer-link" href="mailto:ro@sellavi.com" target="_blank">ro@sellavi.com</a> și să ne spui despre organizația ta. Îți vom oferi o ofertă specială.`,
            }
        },
        benefits: {
            title: 'Cu orice plan, primești',
            text: 'Fără scris cu litere mici: primești ceea ce se vede!',
            cards: {
                card1: 'Spațiu nelimitat de găzduire și stocare a datelor',
                card2: 'Integrare cu instrumente puternice de marketing',
                card3: 'Protecție completă a datelor și plăți sigure',
                card4: 'Suport tehnic live',
                card5: 'Soluție logistică încorporată',
                card6: 'Soluții de plată online încorporate'
            },
            textTrialBtn: 'Începe perioada de probǎ gratuită de 14 zile'
        },
        suitable: {
            title: 'Perfect pentru orice afacere',
            chartText: 'din clienți în segmentul selectat',
            small: {
                title: 'Mici',
                mainDescriptions: `dintre întreprinderile mici și prospere optează pentru un plan Sellavi`,
                whyPoints1: `Economii de până la 85% din bugetul lor`,
                whyPoints2: `Procesele automate de management al afacerii economisesc 75% din timp`,
                whyPoints3: `Actualizări frecvente ale platformei, analize convenabile ale vânzărilor și asistență live`,
            },
            medium: {
                title: 'Medii',
                mainDescriptions: `dintre proprietarii de afaceri care generează peste 37.000 LEI venituri lunare aleg planul Sellavi Plus pentru a avea succes în comerțul electronic.`,
                whyPoints1: `Manager personal disponibil pentru suport dedicat și consultații de marketing`,
                whyPoints2: `Managementul proceselor de afaceri și al logisticii dintr-un singur panou de bord simplu de utilizat`,
                whyPoints3: `Configurare personalizată a magazinului pentru a se alinia nevoilor specifice de afaceri ale clientului`,
            },
            large: {
                title: 'Mari',
                mainDescriptions: `dintre companiile mari au îmbrățișat Sellavi, realizând o creștere remarcabilă de peste 35% prin valorificarea puterii comerțului online.`,
                whyPoints1: `Control total și operațiuni de afaceri online optimizate`,
                whyPoints2: `Integrare ușoară a oricărui serviciu prin API-ul Sellavi`,
                whyPoints3: `Condiții de angajament adaptabile pentru a răspunde nevoilor unice ale clientului`,
            },
            why: 'De ce?'
        }
    },
    affiliateProgram: {
        title: "Programul de afiliere al Sellavi",
        text: "Fǎ cunoștință cu programul nostru de afiliere și devino partenerul de afaceri al Sellavi!",
        mobileCards: {
            card1: {
                text: "Vânzări totale"
            },
            card2: {
                text: "Cărucioare abandonate"
            },
            card3: {
                text: "Număr de comenzi"
            },
            card4: {
                text: "Articole abandonate"
            }
        },
        pros: {
            title: "Devino partenerul de afaceri al Sellavi",
            cardsTitle: {
                card1: 'Comision pentru fiecare client nou',
                card2: 'Îndrumare și asistență tehnică prioritară',
                card3: 'Campanii speciale de marketing',
                card4: 'Oportunitatea de a crește orice afacere'
            }
        },
        overview: {
            title: 'Prezentarea generală a programului',
            titleSecond: 'Cum vei face bani cu Sellavi',
            textFirst: `Programul nostru este accesibil unei game largi de profesii, de la dezvoltatori la agenții digitale și oferă condiții speciale de servicii pentru clienții tăi. Acesta te echipează cu instrumentele necesare pentru a-ți ajuta publicul să lanseze și să dezvolte afaceri online de succes pe platforma noastră.`,
            textSecond: `În timp ce le ghidezi călătoria, vei debloca oportunități pentru comisioane de recomandare competitive și te vei impune ca lider în domeniul tău. Înscrierea este complet gratuită, așa că înscrie-te astăzi și deblochează potențialul de a prospera împreună cu noi.`,
            cardsMakeMoney: {
                card1: 'Invită-ți clienții să creeze un magazin online pe platforma noastră',
                card2: "Dă-le codul tău de cupon, astfel încât să știm că sunt clienții tăi.",
                card3: "Odată ce folosesc codul tău și plătesc pentru unul dintre planuri, vei primi comision de 20% pentru fiecare client"
            }
        },
        target: {
            title: 'Cui se adresează programul nostru?',
            cardsTarget: {
                title1: "Dezvoltatori de site-uri web",
                title2: "Designeri Profesioniști",
                title3: "Copywriteri și creatori de conținut",
                title4: "Consultanți de afaceri",
                title5: "Consultanți de marketing",
                title6: "Antrenori personali",
                title7: "Specialiști în programele de afiliere",
                title8: "Antreprenori",
                title9: "Agenții SEO",
                title10: "Companii SEM (Search Engine Marketing)",
                title11: "Companii de Social Media Marketing",
                title12: "Managerii de cluburi de clienți",
                title13: "Influenceri",
                title14: "Manageri de site-uri e-commerce",
            }
        },
        form: {
            title: 'DESCHIDE NOI ORIZONTURI',
            text: 'pentru afacerea ta și partenerii tăi',
            registration: 'Formular de înregistrare',
            fullName: 'Nume și prenume',
            email: 'E-mail',
            phone: 'Număr de telefon',
            business: 'Numele firmei (opțional)',
            occupation: 'Poziție (opțional)',
            checkLabel: `Confirm că am citit și sunt de acord cu <a href='/#' target='_blank'>prevederile acordului de cooperare</a>`,
            textBtn: 'Devino partenerul nostru',
            successText: 'Am trimis cu succes cererea dumneavoastră de parteneriat'
        }
    },
    blog: {
        title: 'Cum să prosperi în comerțul electronic',
        subscribeForm: {
            title: `Vrei să fii la curent cu cele mai recente știri din domeniul comerțului electronic? Abonează-te la newsletter-ul nostru!`,
            textBtn: 'Abonează-te',
            placeholder: 'Adresa de e-mail',
        },
        tags: {
            smm: 'SMM',
            ecomm: 'E-commerce',
            startup: 'Lansarea',
            onlinestore: 'Magazin online',
            tech: 'Tech',
            marketing: 'Vanzari și Marketing'
        },
        followInSocial: 'Urmărește Sellavi pe rețelele sociale'
    }
}