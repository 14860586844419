import { Link } from "react-router-dom"
import { Provider, LikeButton } from "@lyket/react";
import { E_COMMERCE, MARKETING_AND_SALES, ONLINE_STORE, PHOTO_URL, SMM, STARTING_UP, TECH, apiKeyForLikes, productIdStorageKey } from "../../../assets/utils/constants";
import { compareOptions } from "../../../assets/utils/utils";
import { TranslationContext } from "../../../assets/contexts/translationContext";
import { useContext } from "react";
// import { useContext } from "react";
// import { ThemeContext } from "../../../assets/contexts/themeContext";

function NormalCard({ item, i }) {
    const { blogArticle } = useContext(TranslationContext);
    const { blog } = useContext(TranslationContext);
    // const { theme } = useContext(ThemeContext);
    const handelBtn = (e) => {
        e.stopPropagation();

        //e.preventDefault();
        // e.stopImmediatePropagation()
        //localStorage.setItem('like', JSON.stringify(likeId));
    }

    let cartArray = JSON.parse(localStorage.getItem(productIdStorageKey)) || [];

    function handleAddToCart(item) {
        let cartArray = JSON.parse(localStorage.getItem(productIdStorageKey)) || [];

        function isSameItemOptions(cartItem, newItem) {
            if (
                !newItem.options ||
                newItem.options.length === 0 ||
                !cartItem.options ||
                cartItem.options.length === 0
            ) {
                return true; // Treat as same if either item doesn't have options
            }
            const comparisonResult = compareOptions(
                newItem.options,
                cartItem.options
            );
            return comparisonResult.differences.length === 0;
        }
        const existingCartItemIndex = cartArray.findIndex(
            (cartItem) => cartItem.id === item.id && isSameItemOptions(cartItem, item)
        );
        if (existingCartItemIndex !== -1) {
            cartArray[existingCartItemIndex].count += 1;
        } else {
            const newItem = {
                id: item.id,
                count: item.likes_count + 1,
            };
            cartArray.push(newItem);
        }
        localStorage.setItem(productIdStorageKey, JSON.stringify(cartArray));
    }

    function handleRemoveFromCart(itemToRemove, isAll) {
        let cartArray = JSON.parse(localStorage.getItem(productIdStorageKey)) || [];

        function isSameItemOptions(cartItem, item) {
            if (!item.options || item.options.length === 0 || !cartItem.options || cartItem.options.length === 0) {
                return true; // Consider items the same if either has no options
            }
            const comparisonResult = compareOptions(item.options, cartItem.options);
            return comparisonResult.differences.length === 0;
        }
        const itemIndex = cartArray.findIndex(cartItem =>
            cartItem.id === itemToRemove.id && isSameItemOptions(cartItem, itemToRemove));

        if (isAll) {
            cartArray = cartArray.filter(cartItem =>
                !(cartItem.id === itemToRemove.id && isSameItemOptions(cartItem, itemToRemove)));
        } else {
            if (itemIndex !== -1) {
                if (cartArray[itemIndex].count > 1) {
                    cartArray[itemIndex].count -= 1;

                } else {
                    cartArray.splice(itemIndex, 1);
                }
            }
        }
        localStorage.setItem(productIdStorageKey, JSON.stringify(cartArray));
    }

    return (
        <div key={`normal-card-${item.id}`}>
            <Link to={`/blog/post/${item.translit_name}`}>
                <img alt='' src={`${PHOTO_URL}/${item.vertical_preview?.hash}${item.vertical_preview?.ext}`} className='blog__main-grid__img blog__main-grid_starting__img' ></img>
                {item.tag ?
                    <div className='blog__main-grid__type' style={{ background: item.tag.colour ? item.tag.colour : '#35C650' }}>
                        {item.tag.title === ONLINE_STORE ? `${blog.tags.onlinestore}` :
                            item.tag.title === SMM ? `${blog.tags.smm}` :
                                item.tag.title === STARTING_UP ? `${blog.tags.startup}` :
                                    item.tag.title === E_COMMERCE ? `${blog.tags.ecomm}` :
                                        item.tag.title === TECH ? `${blog.tags.tech}` :
                                            item.tag.title === MARKETING_AND_SALES ? `${blog.tags.marketing}` :
                                                ''
                        }
                    </div>
                    : ''}
                <p className='blog__main-grid_starting__title'>{item.title}</p>
                <p className='blog__main-grid_starting__subtitle'>{item.short_description}</p>
            </Link>
            <div className='blog__main-grid__time-and-like'>
                <Link to={`/blog/post/${item.translit_name}`}>
                    {item.read_time ?
                        <p className='blog__main-grid__time'>{blogArticle.readTime} — {item.read_time} {blogArticle.min}</p>
                        : null}
                </Link>
                <div
                    className='blog__main-grid__like-box'>
                    {cartArray.find(city => city.id === item.id) ?
                        <button
                            onClick={() => handleRemoveFromCart(item, true)}
                            className='blog__main-grid__like-box'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                <path d="M1.23682 1.76929C1.56624 1.46851 1.93148 1.25008 2.33252 1.11401C2.73356 0.977946 3.13102 0.909912 3.5249 0.909912C3.84717 0.909912 4.16048 0.961833 4.46484 1.06567C4.76921 1.16951 5.04671 1.29305 5.29736 1.43628C5.4764 1.53654 5.64111 1.64217 5.7915 1.75317C5.94189 1.86418 6.07438 1.96981 6.18896 2.07007C6.30355 1.96981 6.43783 1.86418 6.5918 1.75317C6.74577 1.64217 6.90869 1.53654 7.08057 1.43628C7.33122 1.29305 7.60872 1.16951 7.91309 1.06567C8.21745 0.961833 8.53434 0.909912 8.86377 0.909912C9.25049 0.909912 9.64437 0.977946 10.0454 1.11401C10.4465 1.25008 10.8117 1.46851 11.1411 1.76929C11.4705 2.07007 11.7355 2.45679 11.936 2.92944C12.1366 3.4021 12.2368 3.96069 12.2368 4.60522C12.2368 4.95614 12.1759 5.30526 12.0542 5.65259C11.9325 5.99992 11.7677 6.34188 11.5601 6.67847C11.3595 7.01506 11.125 7.34806 10.8564 7.67749C10.5879 8.00692 10.3032 8.3256 10.0024 8.63354C9.70166 8.94865 9.39014 9.25301 9.06787 9.54663C8.74561 9.83309 8.42692 10.1088 8.11182 10.3738C7.79671 10.6388 7.49235 10.8858 7.19873 11.115C6.91227 11.3442 6.65088 11.5518 6.41455 11.738L6.18896 11.9099L5.97412 11.738C5.73779 11.5518 5.47282 11.3442 5.1792 11.115C4.89274 10.8858 4.59196 10.6388 4.27686 10.3738C3.96175 10.1088 3.64307 9.83309 3.3208 9.54663C2.99854 9.25301 2.68701 8.94865 2.38623 8.63354C2.07829 8.3256 1.79004 8.00692 1.52148 7.67749C1.25293 7.34806 1.01839 7.01506 0.817871 6.67847C0.61735 6.34188 0.454427 5.99992 0.329102 5.65259C0.203776 5.30526 0.141113 4.95614 0.141113 4.60522C0.141113 3.96069 0.241374 3.4021 0.441895 2.92944C0.642415 2.45679 0.907389 2.07007 1.23682 1.76929Z"
                                    fill={"red"}
                                />
                            </svg>
                            {cartArray.map((itemIdCard, i) => {
                                return (
                                    item.id === itemIdCard.id && (itemIdCard.count - 1) === item.likes_count ?
                                        <div className='blog__main-grid__like'>{itemIdCard.count}</div>
                                        : null
                                )
                            })}
                        </button>
                        :
                        <button
                            onClick={() => handleAddToCart(item)}
                            className='blog__main-grid__like-box'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                <path d="M1.23682 1.76929C1.56624 1.46851 1.93148 1.25008 2.33252 1.11401C2.73356 0.977946 3.13102 0.909912 3.5249 0.909912C3.84717 0.909912 4.16048 0.961833 4.46484 1.06567C4.76921 1.16951 5.04671 1.29305 5.29736 1.43628C5.4764 1.53654 5.64111 1.64217 5.7915 1.75317C5.94189 1.86418 6.07438 1.96981 6.18896 2.07007C6.30355 1.96981 6.43783 1.86418 6.5918 1.75317C6.74577 1.64217 6.90869 1.53654 7.08057 1.43628C7.33122 1.29305 7.60872 1.16951 7.91309 1.06567C8.21745 0.961833 8.53434 0.909912 8.86377 0.909912C9.25049 0.909912 9.64437 0.977946 10.0454 1.11401C10.4465 1.25008 10.8117 1.46851 11.1411 1.76929C11.4705 2.07007 11.7355 2.45679 11.936 2.92944C12.1366 3.4021 12.2368 3.96069 12.2368 4.60522C12.2368 4.95614 12.1759 5.30526 12.0542 5.65259C11.9325 5.99992 11.7677 6.34188 11.5601 6.67847C11.3595 7.01506 11.125 7.34806 10.8564 7.67749C10.5879 8.00692 10.3032 8.3256 10.0024 8.63354C9.70166 8.94865 9.39014 9.25301 9.06787 9.54663C8.74561 9.83309 8.42692 10.1088 8.11182 10.3738C7.79671 10.6388 7.49235 10.8858 7.19873 11.115C6.91227 11.3442 6.65088 11.5518 6.41455 11.738L6.18896 11.9099L5.97412 11.738C5.73779 11.5518 5.47282 11.3442 5.1792 11.115C4.89274 10.8858 4.59196 10.6388 4.27686 10.3738C3.96175 10.1088 3.64307 9.83309 3.3208 9.54663C2.99854 9.25301 2.68701 8.94865 2.38623 8.63354C2.07829 8.3256 1.79004 8.00692 1.52148 7.67749C1.25293 7.34806 1.01839 7.01506 0.817871 6.67847C0.61735 6.34188 0.454427 5.99992 0.329102 5.65259C0.203776 5.30526 0.141113 4.95614 0.141113 4.60522C0.141113 3.96069 0.241374 3.4021 0.441895 2.92944C0.642415 2.45679 0.907389 2.07007 1.23682 1.76929Z"
                                    fill={"var(--neutral-active)"}
                                />
                            </svg>
                            <div className='blog__main-grid__like'>{item.likes_count}</div>
                        </button>
                    }
                </div>
            </div>
        </div>

    )
}
export default NormalCard