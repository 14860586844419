import { useContext } from "react";
import { TranslationContext } from "../../assets/contexts/translationContext";
import { ArrowIcon, CloseIcon } from "../../assets/icons/icons";
import "./Popup.css";

function Popup({ isOpen, onClose, children, preloader_visible }) {
  const { popupSignUp } = useContext(TranslationContext);
  return (
    <div className={`popup ${isOpen ? "popup_opened" : ""} ${preloader_visible? 'popup_with-preloader' : ''}`}>
      <div className="popup__container">
        {!preloader_visible ?
          <div className="popup__control-btns">
            <button className="popup__btn" type="button" onClick={onClose}>
              <ArrowIcon
                mainClassName="popup__back-icon"
                strokeClassName="popup__back-icon-stroke"
              />
              <p className="popup__btn-text">{popupSignUp.back}</p>
            </button>
            <button className="popup__btn" type="button" onClick={onClose}>
              <CloseIcon
                mainClassName="popup__close-icon"
                strokeClassName="popup__close-icon-stroke"
                fillClassName="popup__close-icon-fill"
              />
            </button>
          </div>
          : null}

        {children}
      </div>
    </div>
  );
}

export default Popup;
