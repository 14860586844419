import { useContext, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import CountUp from 'react-countup';

import './Suitable.css';
import { SellaviPointIcon } from '../../../assets/icons/price';
import DonutChart from './DonutChart/DonutChart';
import { LARGE_TYPE, MEDIUM_TYPE, SMALL_TYPE } from '../../../assets/utils/suitable';
import { TranslationContext } from '../../../assets/contexts/translationContext';


const descriptionVariants = {
    initial: { opacity: 0, x: -30 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 30 },
};


function InfoButton({ item, isSelected, onClick }) {
    return (
        <button
            className={`suitable__info-btn ${isSelected ? 'suitable__info-btn_selected' : ''}`}
            type='button'
            onClick={onClick}
        >
            {item.title}
        </button>
    );
}
const whyPointVariants = {
    initial: { opacity: 0, x: -30 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 30 },
};

function WhyPoint({ item, delay }) {
    return (
        <motion.div
            className='suitable__info-why-item'
            variants={whyPointVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.5, delay }}
        >
            <SellaviPointIcon
                mainClassName={'suitable__info-why-item-icon'}
            />
            <p className='suitable__info-why-item-text'>{item.title}</p>
        </motion.div>
    );
}


function Suitable() {
    const { prices } = useContext(TranslationContext);
    const { suitable } = prices;
    const { small, medium, large } = suitable;

    const SMALL_TYPE = {
        type: 'small',
        title: `${small.title}`,
        main_percent: 90,
        main_descriptions: `${small.mainDescriptions}`,
        why_points: [
            {
                title: `${small.whyPoints1}`,
            },
            {
                title: `${small.whyPoints2}`,
            },
            {
                title: `${small.whyPoints3}`,
            },
        ],
        clients_percent: 79,
    }

    const MEDIUM_TYPE = {
        type: 'medium',
        title: `${medium.title}`,
        main_percent: 74,
        main_descriptions: `${medium.mainDescriptions}`,
        why_points: [
            {
                title: `${medium.whyPoints1}`,
            },
            {
                title: `${medium.whyPoints2}`,
            },
            {
                title: `${medium.whyPoints3}`,
            },
        ],
        clients_percent: 17,
    }

    const LARGE_TYPE = {
        type: 'large',
        title: `${large.title}`,
        main_percent: 17,
        main_descriptions: `${large.mainDescriptions}`,
        why_points: [
            {
                title: `${large.whyPoints1}`,
            },
            {
                title: `${large.whyPoints2}`,
            },
            {
                title: `${large.whyPoints3}`,
            },
        ],
        clients_percent: 4,
    }
    const [selected, setSelected] = useState(SMALL_TYPE)
    const [perviuosSelected, setPerviuosSelected] = useState(SMALL_TYPE)

    const BTNS = [
        SMALL_TYPE,
        MEDIUM_TYPE,
        LARGE_TYPE,
    ]

    return (
        <div className='suitable'>
            <div className='suitable__content'>
                <div className='suitable__info'>
                    <p className='suitable__info-title'>{suitable.title}</p>
                    <div className='suitable__info-btns'>
                        {BTNS.map((item) => (
                            <InfoButton
                                key={`suitable__info-btn-${item.type}`}
                                item={item}
                                isSelected={selected.type === item.type}
                                onClick={() => {
                                    setPerviuosSelected(selected)
                                    setSelected(item)
                                }}
                            />
                        ))}
                    </div>
                    {selected.type == SMALL_TYPE.type ?
                        <>
                            <div className='suitable__chart suitable__chart_mobile'>
                                <DonutChart
                                    outerPercentage={SMALL_TYPE.main_percent}
                                    innerPercentage={SMALL_TYPE.clients_percent}
                                    prevInnerPercentage={perviuosSelected.clients_percent}
                                    type={SMALL_TYPE.type}
                                />
                            </div>
                            <p className='suitable__info-main-percent'>
                                <CountUp
                                    className='suitable__info-main-percent-numbers'
                                    end={SMALL_TYPE.main_percent}
                                    duration={2.5}
                                    enableScrollSpy={true} scrollSpyOnce={true}
                                    start={perviuosSelected.main_percent} // You can adjust the starting number for a different effect
                                />
                                %</p>
                            <AnimatePresence mode='wait'>
                                <motion.p
                                    key={`suitable__info-main-description_${SMALL_TYPE.type}`}
                                    variants={descriptionVariants}
                                    initial="initial"
                                    animate="animate"
                                    exit="exit"
                                    transition={{ duration: 0.2 }}
                                    className='suitable__info-main-description'
                                    layout

                                >
                                    {SMALL_TYPE.main_descriptions}
                                </motion.p>
                            </AnimatePresence>
                            <p className='suitable__info-why-title'>{suitable.why}</p>
                            <AnimatePresence mode='wait'>
                                <div className='suitable__info-why-items'>
                                    {SMALL_TYPE.why_points.map((item, i) => (
                                        <WhyPoint
                                            key={`suitable__info-why-item_${SMALL_TYPE.type}${i}`}
                                            item={item}
                                            delay={i * 0.1} // Each subsequent item will start animating 0.1 seconds later
                                        />
                                    ))}

                                </div>
                            </AnimatePresence>
                        </>
                        :
                        selected.type == MEDIUM_TYPE.type ?
                            <>
                                <div className='suitable__chart suitable__chart_mobile'>
                                    <DonutChart
                                        outerPercentage={MEDIUM_TYPE.main_percent}
                                        innerPercentage={MEDIUM_TYPE.clients_percent}
                                        prevInnerPercentage={perviuosSelected.clients_percent}
                                        type={MEDIUM_TYPE.type}
                                    />
                                </div>
                                <p className='suitable__info-main-percent'>
                                    <CountUp
                                        className='suitable__info-main-percent-numbers'
                                        end={MEDIUM_TYPE.main_percent}
                                        duration={2.5}
                                        enableScrollSpy={true} scrollSpyOnce={true}
                                        start={perviuosSelected.main_percent} // You can adjust the starting number for a different effect
                                    />
                                    %</p>
                                <AnimatePresence mode='wait'>
                                    <motion.p
                                        key={`suitable__info-main-description_${MEDIUM_TYPE.type}`}
                                        variants={descriptionVariants}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        transition={{ duration: 0.2 }}
                                        className='suitable__info-main-description'
                                        layout

                                    >
                                        {MEDIUM_TYPE.main_descriptions}
                                    </motion.p>
                                </AnimatePresence>
                                <p className='suitable__info-why-title'>{suitable.why}</p>
                                <AnimatePresence mode='wait'>
                                    <div className='suitable__info-why-items'>
                                        {MEDIUM_TYPE.why_points.map((item, i) => (
                                            <WhyPoint
                                                key={`suitable__info-why-item_${MEDIUM_TYPE.type}${i}`}
                                                item={item}
                                                delay={i * 0.1} // Each subsequent item will start animating 0.1 seconds later
                                            />
                                        ))}

                                    </div>
                                </AnimatePresence>
                            </>
                            :
                            selected.type == LARGE_TYPE.type ?
                                <>
                                    <div className='suitable__chart suitable__chart_mobile'>
                                        <DonutChart
                                            outerPercentage={LARGE_TYPE.main_percent}
                                            innerPercentage={LARGE_TYPE.clients_percent}
                                            prevInnerPercentage={perviuosSelected.clients_percent}
                                            type={LARGE_TYPE.type}
                                        />
                                    </div>
                                    <p className='suitable__info-main-percent'>
                                        <CountUp
                                            className='suitable__info-main-percent-numbers'
                                            end={LARGE_TYPE.main_percent}
                                            duration={2.5}
                                            enableScrollSpy={true} scrollSpyOnce={true}
                                            start={perviuosSelected.main_percent} // You can adjust the starting number for a different effect
                                        />
                                        %</p>
                                    <AnimatePresence mode='wait'>
                                        <motion.p
                                            key={`suitable__info-main-description_${LARGE_TYPE.type}`}
                                            variants={descriptionVariants}
                                            initial="initial"
                                            animate="animate"
                                            exit="exit"
                                            transition={{ duration: 0.2 }}
                                            className='suitable__info-main-description'
                                            layout

                                        >
                                            {LARGE_TYPE.main_descriptions}
                                        </motion.p>
                                    </AnimatePresence>
                                    <p className='suitable__info-why-title'>{suitable.why}</p>
                                    <AnimatePresence mode='wait'>
                                        <div className='suitable__info-why-items'>
                                            {LARGE_TYPE.why_points.map((item, i) => (
                                                <WhyPoint
                                                    key={`suitable__info-why-item_${LARGE_TYPE.type}${i}`}
                                                    item={item}
                                                    delay={i * 0.1} // Each subsequent item will start animating 0.1 seconds later
                                                />
                                            ))}

                                        </div>
                                    </AnimatePresence>
                                </>
                                : null
                    }
                </div>
                <div className='suitable__chart suitable__chart_pc'>
                    <DonutChart
                        outerPercentage={selected.main_percent}
                        innerPercentage={selected.clients_percent}
                        prevInnerPercentage={perviuosSelected.clients_percent}
                        type={selected.type}
                    />
                </div>
            </div>
        </div>
    );
}

export default Suitable