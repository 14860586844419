import './Bennefits.css';

import card_1 from '../../../assets/images/bennefits/card-1.webp'
import card_2 from '../../../assets/images/bennefits/card-2.webp'
import card_3 from '../../../assets/images/bennefits/card-3.webp'
import card_4 from '../../../assets/images/bennefits/card-4.webp'
import card_5 from '../../../assets/images/bennefits/card-5.webp'
import card_6 from '../../../assets/images/bennefits/card-6.webp'
import { useContext } from 'react';
import { TranslationContext } from '../../../assets/contexts/translationContext';

function Bennefits({ onSignupOpen }) {
    const { prices } = useContext(TranslationContext);
    const { benefits } = prices;
    const { cards } = benefits;

    const CARDS = [
        {
            img: card_1,
            text: `${cards.card1}`,
        },
        {
            img: card_2,
            text: `${cards.card2}`,
        },
        {
            img: card_3,
            text: `${cards.card3}`,
        },
        {
            img: card_4,
            text: `${cards.card4}`,
        },
        {
            img: card_5,
            text: `${cards.card5}`,
        },
        {
            img: card_6,
            text: `${cards.card6}`,
        },
    ]
    return (
        <div className='bennefits'>
            <h4 className='bennefits__title'>{benefits.title}</h4>
            <p className='bennefits__subtitle'>{benefits.text}</p>
            <div className='bennefits__items'>
                {CARDS.map((item, i) => (
                    <div className='bennefits__item' key={`bennefits__item${i}`}>
                        <img className='bennefits__item-img' src={item.img} alt='' />
                        <p className='bennefits__item-text'>{item.text}</p>
                    </div>
                ))}

            </div>
            <button className='bennefits__btn' type='button' onClick={onSignupOpen}>
                {benefits.textTrialBtn}
            </button>
        </div>
    );
}

export default Bennefits