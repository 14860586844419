import { useContext, useState } from "react";
import CustomInput from "../../CustomInput/CustomInput";
import Checkbox from "../../Checkbox/Checkbox";
import bg from "../../../assets/images/partners/form/bg.webp";
import tl from "../../../assets/images/partners/form/left-top-stars.webp";
import bl from "../../../assets/images/partners/form/left-bottom-stars.webp";
import tr from "../../../assets/images/partners/form/right-top-stars.webp";
import br from "../../../assets/images/partners/form/right-bottom-stars.webp";
import "./PartnersForm.css";
import { TranslationContext } from "../../../assets/contexts/translationContext";
import { BOT_TOKEN, CHAT_ID, CHAT_ID_PARTNERS } from "../../../assets/utils/constants";
import { sendTextTgBot } from "../../../assets/utils/utils";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";
import { motion } from "framer-motion";

function PartnersForm({ valuesValidity, values, onChange, cleanForm }) {
  const { affiliateProgram } = useContext(TranslationContext);
  const { form } = affiliateProgram;
  // const { cardsTarget } = target;

  const [valuesCheck, setCheck] = useState(false);
  const [successTextVisible, setSuccessTextVisible] = useState(false);

  function handleChange() {
    setCheck(!valuesCheck)
  }

  const isDisabled = !(valuesValidity.email?.validState && valuesValidity.phone?.validState);
  const [preloaders, setPreloaders] = useState(false)

  const handleSubmit = async (evt) => {
    if (valuesCheck && values.name && values.phone && values.email && !isDisabled) {
      evt.preventDefault();
      sendTextTgBot(CHAT_ID_PARTNERS, `________
New partners lead

Locale: RO
Name: ${values.name}
Phone: ${values.phone}
Email: ${values.email}
Business: ${values.business_name ? values.business_name : '-'}
Ocupation: ${values.occupation ? values.occupation : '-'}
________`, setPreloaders)
      cleanForm()
      setSuccessTextVisible(true)
    } else {
      evt.preventDefault();
    }
  };

  return (
    <div className="partners-form">
      <p className="partners-form__title">{form.title}</p>
      <p className="partners-form__subtitle">{form.text}</p>
      <form className="partners-form__form" onSubmit={handleSubmit}>
        <div
          className="partners-form__container">
          <p className="partners-form__form-title">{form.registration}</p>
          <div className="partners-form__inputs-block">
            <CustomInput
              name="name"
              label={form.fullName}
              placeholder={form.fullName}
              value={values.name}
              onChange={onChange}
            />
            <CustomInput
              name="email"
              label={form.email}
              placeholder={form.email}
              value={values.email}
              onChange={onChange}
            />
            <CustomInput
              name="phone"
              label={form.phone}
              placeholder={form.phone}
              value={values.phone}
              onChange={onChange}
            />
            <CustomInput
              name="business_name"
              label={form.business}
              placeholder={form.business}
              value={values.business_name}
              onChange={onChange}
            />
            <CustomInput
              name="occupation"
              label={form.occupation}
              placeholder={form.occupation}
              value={values.occupation}
              onChange={onChange}
            />
          </div>
          <Checkbox
            name="confirm"
            label={`${form.checkLabel}`}
            checked={valuesCheck}
            onChange={handleChange}
          />
          <button className={`partners-form__submit-btn ${valuesCheck && values.name && values.phone && values.email && !isDisabled ? 'partners-form__submit-btn_submit' : ''}`} type="submit">
            {preloaders ? <MiniPreloader /> : form.textBtn}
          </button>
          {successTextVisible ?
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2.0 }}
              className="partners-form__success-text"
            >
              {form.successText}
            </motion.div>
            : null}
        </div>
      </form>

      <img className="partners-form__bg" src={bg} alt="" />
      <img
        className="partners-form__stars partners-form__stars_type_top-left"
        src={tl}
        alt=""
      />
      <img
        className="partners-form__stars partners-form__stars_type_bottom-left"
        src={bl}
        alt=""
      />
      <img
        className="partners-form__stars partners-form__stars_type_top-right"
        src={tr}
        alt=""
      />
      <img
        className="partners-form__stars partners-form__stars_type_bottom-right"
        src={br}
        alt=""
      />
    </div>
  );
}

export default PartnersForm;
