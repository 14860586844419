import commission from "../../../assets/images/partners/pros/commission-icon.webp";
import support from "../../../assets/images/partners/pros/support-icon.webp";
import marketing from "../../../assets/images/partners/pros/marketing-icon.webp";
import opportunity from "../../../assets/images/partners/pros/opportunity-icon.webp";
import "./PartnersPros.css";
import { useContext } from "react";
import { TranslationContext } from "../../../assets/contexts/translationContext";

function PartnersPros() {
  const { affiliateProgram } = useContext(TranslationContext);
  const { pros } = affiliateProgram;
  const { cardsTitle } = pros;

  const cards = [
    {
      text: `${cardsTitle.card1}`,
      icon: commission,
    },
    {
      text: `${cardsTitle.card2}`,
      icon: support,
    },
    {
      text: `${cardsTitle.card3}`,
      icon: marketing,
    },
    {
      text: `${cardsTitle.card4}`,
      icon: opportunity,
    },
  ];

  return (
    <div className="partners-pros">
      <p className="partners-pros__title">{pros.title}</p>
      <ul className="partners-pros__list">
        {cards.map((item, i) => (
          <li className="partners-pros__item" key={`card-${i}`}>
            <p className="partners-pros__text">{item.text}</p>
            <img className="partners-pros__icon" src={item.icon} alt="" />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default PartnersPros;
