import { motion, useAnimation, useInView } from 'framer-motion';
import React, { useContext, useEffect, useRef } from 'react';


import './Store.css';
import item_1 from '../../../assets/images/store/item-1.webp'
import item_2 from '../../../assets/images/store/item-2.webp'
import item_3 from '../../../assets/images/store/item-3.webp'
import item_4 from '../../../assets/images/store/item-4.webp'
import item_5 from '../../../assets/images/store/item-5.webp'
import item_6 from '../../../assets/images/store/item-6.webp'
import item_7 from '../../../assets/images/store/item-7.webp'
import item_8 from '../../../assets/images/store/item-8.webp'
import item_9 from '../../../assets/images/store/item-9.webp'
import item_10 from '../../../assets/images/store/item-10.webp'
import item_11 from '../../../assets/images/store/item-11.webp'
import item_12 from '../../../assets/images/store/item-12.webp'
import item_13 from '../../../assets/images/store/item-13.webp'
import item_14 from '../../../assets/images/store/item-14.webp'
import item_15 from '../../../assets/images/store/item-15.webp'
import item_16 from '../../../assets/images/store/item-16.webp'
import item_17 from '../../../assets/images/store/item-17.webp'
import item_18 from '../../../assets/images/store/item-18.webp'
import item_19 from '../../../assets/images/store/item-19.webp'
import item_20 from '../../../assets/images/store/item-20.webp'
import item_21 from '../../../assets/images/store/item-21.webp'
import item_22 from '../../../assets/images/store/item-22.webp'
import item_23 from '../../../assets/images/store/item-23.webp'
import item_24 from '../../../assets/images/store/item-24.webp'
import item_25 from '../../../assets/images/store/item-25.webp'
import item_26 from '../../../assets/images/store/item-26.webp'
import item_27 from '../../../assets/images/store/item-27.webp'
import item_28 from '../../../assets/images/store/item-28.webp'
import item_29 from '../../../assets/images/store/item-29.webp'
import item_30 from '../../../assets/images/store/item-30.webp'
import item_31 from '../../../assets/images/store/item-31.webp'
import item_32 from '../../../assets/images/store/item-32.webp'
import item_33 from '../../../assets/images/store/item-33.webp'
import item_34 from '../../../assets/images/store/item-34.webp'
import item_35 from '../../../assets/images/store/item-35.webp'
import item_36 from '../../../assets/images/store/item-36.webp'
import item_37 from '../../../assets/images/store/item-37.webp'
import item_38 from '../../../assets/images/store/item-38.webp'
import item_39 from '../../../assets/images/store/item-39.webp'
import item_40 from '../../../assets/images/store/item-40.webp'
import item_41 from '../../../assets/images/store/item-41.webp'
import item_42 from '../../../assets/images/store/item-42.webp'
import { TranslationContext } from '../../../assets/contexts/translationContext';

function Store({ onSignupOpen }) {
    const { main } = useContext(TranslationContext);
    const { mainFeatures } = main;
    const { iconText } = mainFeatures;

    const ITEMS = [
        {
            title: `${iconText.text1}`,
            img: item_1,
        },
        {
            title: `${iconText.text2}`,
            img: item_2,
        },
        {
            title: `${iconText.text3}`,
            img: item_3,
        },
        {
            title: `${iconText.text4}`,
            img: item_4,
        },
        {
            title: `${iconText.text5}`,
            img: item_5,
        },
        {
            title: `${iconText.text6}`,
            img: item_6,
        },
        {
            title: `${iconText.text7}`,
            img: item_7,
        },
        {
            title: `${iconText.text8}`,
            img: item_8,
        },
        {
            title: `${iconText.text9}`,
            img: item_9,
        },
        {
            title: `${iconText.text10}`,
            img: item_10,
        },
        {
            title: `${iconText.text11}`,
            img: item_11,
        },
        {
            title: `${iconText.text12}`,
            img: item_12,
        },
        {
            title: `${iconText.text13}`,
            img: item_13,
        },
        {
            title: `${iconText.text14}`,
            img: item_14,
        },
        {
            title: `${iconText.text15}`,
            img: item_15,
        },
        {
            title: `${iconText.text16}`,
            img: item_16,
        },
        {
            title: `${iconText.text17}`,
            img: item_17,
        },
        {
            title: `${iconText.text18}`,
            img: item_18,
        },
        {
            title: `${iconText.text19}`,
            img: item_19,
        },
        {
            title: `${iconText.text20}`,
            img: item_20,
        },
        {
            title: `${iconText.text21}`,
            img: item_21,
        },
        {
            title: `${iconText.text22}`,
            img: item_22,
        },
        {
            title: `${iconText.text23}`,
            img: item_23,
        },
        {
            title: `${iconText.text24}`,
            img: item_24,
        },
        {
            title: `${iconText.text25}`,
            img: item_25,
        },
        {
            title: `${iconText.text26}`,
            img: item_26,
        },
        {
            title: `${iconText.text27}`,
            img: item_27,
        },
        {
            title: `${iconText.text28}`,
            img: item_28,
        },
        {
            title: `${iconText.text29}`,
            img: item_29,
        },
        {
            title: `${iconText.text30}`,
            img: item_30,
        },
        {
            title: `${iconText.text31}`,
            img: item_31,
        },
        {
            title: `${iconText.text32}`,
            img: item_32,
        },
        {
            title: `${iconText.text33}`,
            img: item_33,
        },
        {
            title: `${iconText.text34}`,
            img: item_34,
        },
        {
            title: `${iconText.text35}`,
            img: item_35,
        },
        {
            title: `${iconText.text36}`,
            img: item_36,
        },
        {
            title: `${iconText.text37}`,
            img: item_37,
        },
        {
            title: `${iconText.text38}`,
            img: item_38,
        },
        {
            title: `${iconText.text39}`,
            img: item_39,
        },
        {
            title: `${iconText.text40}`,
            img: item_40,
        },
        {
            title: `${iconText.text41}`,
            img: item_41,
        },
        {
            title: `${iconText.text42}`,
            img: item_42,
        },
    ]

    const containerVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                staggerChildren: 0.05,
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };

    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
        if (isInView) {
            controls.start('visible');
        }
    }, [controls, isInView]);

    return (
        <motion.div className='store' ref={ref}
            variants={containerVariants}
            initial="hidden"
            animate={controls}
        >
            <motion.h3 className='store__title' variants={itemVariants}>
                {mainFeatures.titleFirstLine}<br></br>{mainFeatures.titleSecondLine}
            </motion.h3>
            <motion.p className='store__subtitle' variants={itemVariants}>
                {mainFeatures.text}
            </motion.p>
            <motion.div className='store__items'>
                {ITEMS.map((item, i) => (
                    <motion.div className='store__item' key={`store__item_${i}`} variants={itemVariants}>
                        <img className='store__item-img' src={item.img} alt='' />
                        <p className='store__item-title'>{item.title}</p>
                    </motion.div>
                ))}
            </motion.div>
            <motion.button className='store__btn' variants={itemVariants} type='button' onClick={onSignupOpen}>
                {mainFeatures.textBtn}
            </motion.button>
        </motion.div>
    );
}

export default Store