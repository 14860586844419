import { useContext } from 'react';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import {
  BlurLaptop,
  BlurMobile,
  ButtonSmileLaptop,
  ButtonSmileMobile,
} from '../../../assets/icons/icons';
import {
  FOOTER_CONTACTS,
  FOOTER_LOGO_COLOR,
  FOOTER_NAV_LINKS,
} from '../../../assets/utils/constants';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer({ onSignupOpen }) {
  const { footer } = useContext(TranslationContext);
  const { footerLink } = footer;
  const { width } = useWindowSize();

  const FOOTER_CONTACTS = [
    {
      title: `${footer.mail}`,
      link: `mailto:${footer.mail}`
    },
    // {
    //     title: 'support@sellavi.com',
    //     link: 'mailto:support@sellavi.com'
    // },
  ]

  const FOOTER_NAV_LINKS = [
    {
      path: '/about',
      title: `${footerLink.title1}`
    },
    {
      path: '/prices',
      title: `${footerLink.title2}`
    },
    {
      path: '/partners',
      title: `${footerLink.title3}`
    },
    {
      path: '/blog',
      title: `${footerLink.title4}`,
      mobileTitle: `${footerLink.mobileTitle4}`,
    },
    // {
    //     path: '#',
    //     title: 'Free trial'
    // },
  ]

  return (
    <div className='footer'>
      <div className='footer__box'>
        <p className='footer__title footer__title_type_green'>
          {footer.greenTitle}
        </p>
        <p className='footer__title'>{footer.whiteTitle}</p>
        <p className='footer__subtitle'>
          {footer.subtitle}
        </p>
        <button className='footer__button' type='button' onClick={onSignupOpen}>
          {footer.textBtn}
          <div className='button__smile_laptop'>
            <ButtonSmileLaptop />
          </div>
          <div className='button__smile_mobile'>
            <ButtonSmileMobile />
          </div>
        </button>

        <div className='footer__content'>
          <div className='footer__links-block'>
            <ul className='footer__nav-links-list'>
              {FOOTER_NAV_LINKS.map((item, i) => (
                <li
                  className='footer__nav-link-item'
                  key={`footer-nav-link-${i}`}
                >
                  <Link className='footer__nav-link' to={item.path}>
                    {width <= 580 && item.mobileTitle
                      ? item.mobileTitle
                      : item.title}
                  </Link>
                </li>
              ))}
              <li
                className='footer__nav-link-item'
              >
                <button className='footer__nav-link' type='button' onClick={onSignupOpen}>
                  {footer.freeTrial}
                </button>
              </li>
            </ul>

            <ul className='footer__contacts-list'>
              {FOOTER_CONTACTS.map((item, i) => (
                <li
                  className='footer__contact-item'
                  key={`footer-contact-${i}`}
                >
                  <a className='footer__contact-link' href={item.link}>
                    {item.title}
                  </a>
                </li>

              ))}
            </ul>
          </div>

          <ul className='footer__logos-list'>
            {FOOTER_LOGO_COLOR.map((item, i) => (
              <li
                className='footer__logo-item'
                key={`footer-logo-${item.name}`}
              >
                <img
                  className={`footer__logo footer__logo_type_${item.name}`}
                  src={item.logo}
                  alt={item.name}
                />
              </li>
            ))}
          </ul>
        </div>
        <p className='footer__rights'>
          {footer.rightsReserved}
        </p>
      </div>

      <div className='button__blur button__blur_laptop'>
        <BlurLaptop />
      </div>
      <div className='button__blur button__blur_mobile'>
        <BlurMobile />
      </div>
    </div>
  );
}

export default Footer;
