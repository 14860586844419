import { createRef, useContext, useState } from "react";
import validator from "validator";
import "./SubscribeForm.css";
import { TranslationContext } from "../../../assets/contexts/translationContext";
import { sendTextTgBot } from "../../../assets/utils/utils";
import { CHAT_ID } from "../../../assets/utils/constants";

function SubscribeForm({ onEmailChange, email, emailValidity, setSignupValues, setSignupValidity }) {
  const { blog } = useContext(TranslationContext);
  const { subscribeForm } = blog

  function handleSubmit(evt) {
    evt.preventDefault();
    setSignupValues((prevState) => ({
      ...prevState,
      email: ''
    }))
    setSignupValidity((prevState) => ({
      ...prevState,
      email: ''
    }))
    sendTextTgBot(CHAT_ID, `________
    Subscribe to newsletter
    
    Locale: RO
    Email: ${email}
    ________`)
    setFocused(false)

  }

  const [focused, setFocused] = useState(true)
  const ref = createRef(null);

  const handleMouseOut = (currentRef) => {
    setFocused(false)
  };

  const handleMouseOn = (currentRef) => {
    if (document.activeElement === currentRef) {
      setFocused(true)
    }
  };

  return (
    <div className="subscribe">
      <p className="subscribe__title">
        {subscribeForm.title}
      </p>
      {/* <form
        className={`subscribe__form ${Boolean(value) ? "subscribe__form_focus" : ""
          }`}
        onSubmit={handleSubmit}
      >
        <input
          className={`subscribe__input ${Boolean(value) ? "subscribe__input_focus" : ""
            }`}
          name="email"
          type="email"
          inputMode="email"
          value={value || ""}
          placeholder={subscribeForm.placeholder}
          onChange={handleChange}
        />
        <button
          className={`subscribe__submit-btn ${!validity.validState ? "subscribe__submit-btn_disabled" : ""
            }`}
          type="submit"
          disabled={!validity.validState}
        >
          {subscribeForm.textBtn}
        </button>
      </form> */}

      <div className={`head-banner__input-box ${email ? 'head-banner__input-box_inactive' : ''} ${emailValidity?.validState ? 'head-banner__input-box_valid' : ''} ${!focused && !emailValidity?.validState && email ? 'head-banner__input-box_not-valid' : ''}`}>
        <input
          ref={ref}
          onMouseEnter={() => handleMouseOn(ref.current)}
          onFocus={() => handleMouseOn(ref.current)}
          onBlur={() => handleMouseOut(ref.current)}
          className="head-banner__input"
          name={'email'}
          type={'text'}
          inputMode={'text'}
          value={email || ""}
          placeholder={subscribeForm.placeholder}
          onChange={onEmailChange}
        />
        <button className={`head-banner__button head-banner__button_pc ${!emailValidity?.validState && email ? 'head-banner__button_inactive' : ''} ${!email && !emailValidity.validState ? 'head-banner__button_diasbled' : ''}`} type='button'
          onClick={handleSubmit}
        >
          {subscribeForm.textBtn}
        </button>
      </div>
      <button className={`head-banner__button head-banner__button_mobile ${!emailValidity?.validState && email ? 'head-banner__button_inactive' : ''} ${!email && !emailValidity.validState ? 'head-banner__button_diasbled' : ''}`} type='button'
        onClick={handleSubmit}
      >
        {subscribeForm.textBtn}
      </button>

    </div>
  );
}

export default SubscribeForm;
