import React, { useContext, useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';
import { PieChart, Pie, Cell } from 'recharts';

import './DonutChart.css'
import { LARGE_TYPE, MEDIUM_TYPE, SMALL_TYPE } from '../../../../assets/utils/suitable';
import { TranslationContext } from '../../../../assets/contexts/translationContext';

const MIN_SIZE = 2;

function getGradColours(type) {
    switch (type) {
        case SMALL_TYPE.type:
            return {
                start: '#FFB800',
                end: '#F27400',
            }
        case MEDIUM_TYPE.type:
            return {
                start: '#FF0000',
                end: '#EDF200',
            }
        case LARGE_TYPE.type:
            return {
                start: '#0085FF',
                end: '#FBFF24',
            }
        default:
            return {
                start: '#FFB800',
                end: '#F27400',
            }
    }
}

const DonutChart = ({ outerPercentage, innerPercentage, prevInnerPercentage, type }) => {
    const dataOuter = [
        { name: 'Filled', value: outerPercentage },
        { name: 'Empty', value: 100 - outerPercentage },
    ];
    const dataInner = [
        { name: 'Filled', value: innerPercentage },
        { name: 'Empty', value: 100 - innerPercentage },
    ];

    const COLORS = ['var(--primary-active)', 'transparent'];
    const [dimensions, setDimensions] = useState({ width: 300, height: 300 });
    const ref = useRef(null);

    const updateDimensions = () => {
        if (ref.current) {
            setDimensions({
                width: ref.current.offsetWidth,
                height: ref.current.offsetHeight
            });
        }
    };

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const radius = Math.min(dimensions.width, dimensions.height) / 2;
    const outerChartProportion = 205 / 872; // 125 as a ratio of a base value (300 for example)
    const innerChartProportion = 50 / 872; // 30 as a ratio of the same base value

    // Calculate radii for outer and inner charts
    const outerRadius = radius * 0.9; // Outer chart's outer radius
    const outerInnerRadius = outerRadius - radius * outerChartProportion; // Outer chart's inner radius
    const gap = radius * innerChartProportion; // Gap between the charts
    const innerOuterRadius = outerInnerRadius - gap; // Inner chart's outer radius
    const innerInnerRadius = innerOuterRadius - gap; // Inner chart's inner radius

    const gradColors = getGradColours(type);

    const adjustDataForBorderRadius = (data, percentage) => {
        return data.map((entry, index) => {
            if (index === 0 && percentage > 0 && percentage <= MIN_SIZE) {
                return { ...entry, value: MIN_SIZE };
            }
            return entry;
        });
    };

    const adjustedDataOuter = adjustDataForBorderRadius(dataOuter, outerPercentage);
    const adjustedDataInner = adjustDataForBorderRadius(dataInner, innerPercentage);

    const { prices } = useContext(TranslationContext);
    const { suitable } = prices;

    return (
        <div className='donut-chart' ref={ref} >
            <PieChart width={dimensions.width} height={dimensions.height}>
                <defs>
                    <linearGradient id="paint0_linear_215_17374" x1="281" y1="0.110107" x2="281" y2="572.11" gradientUnits="userSpaceOnUse" className="donut-chart__gradient">
                        <stop className="donut-chart__gradient" stopColor={gradColors.start} />
                        <stop className="donut-chart__gradient" offset="1" stopColor={gradColors.end} />
                    </linearGradient>
                </defs>
                <Pie
                    data={adjustedDataOuter}
                    cx="50%"
                    cy="50%"
                    innerRadius={outerInnerRadius}
                    outerRadius={outerRadius}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                    cornerRadius={400}
                    startAngle={90}
                    endAngle={-270}
                >
                    {dataOuter.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} stroke='none' />
                    ))}
                </Pie>
                <Pie
                    data={adjustedDataInner}
                    cx="50%"
                    cy="50%"
                    innerRadius={innerInnerRadius}
                    outerRadius={innerOuterRadius}
                    fill="url(#paint0_linear_215_17374)"
                    paddingAngle={5}
                    dataKey="value"
                    cornerRadius={200}
                    startAngle={90}
                    endAngle={-270}
                >
                    {dataInner.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={dataInner.length - 1 === index ? 'transparent' : 'url(#paint0_linear_215_17374)'} stroke='none' />
                    ))}
                </Pie>
            </PieChart>
            <div className='donut-chart__inner' >

                <p className='donut-chart__inner-percent'>
                    <CountUp
                        end={innerPercentage}
                        duration={2.5}
                        enableScrollSpy={true} scrollSpyOnce={true}
                        start={prevInnerPercentage} // You can adjust the starting number for a different effect
                    />
                    %</p>
                <p className='donut-chart__inner-text'>{suitable.chartText}</p>
            </div>
        </div>
    );
};

export default DonutChart;