import { Link, NavLink, useLocation, useSearchParams } from 'react-router-dom';
import './Header.css';
import { LogoIcon, MobileMenuIcon, SunIcon } from '../../assets/icons/icons';
import { LANGUAGES, EN_LANGUAGE, RO_LANGUAGE, THEME_DARK_TYPE, THEME_LIGHT_TYPE } from '../../assets/utils/constants';
import { useContext, useState } from 'react';
import { ThemeContext } from '../../assets/contexts/themeContext';
import useScrollDirection from '../../assets/hooks/useScrollDirection';
import HeaderPopup from './HeaderPopup/HeaderPopup';
import useWindowSize from '../../assets/hooks/useWindowSize';
import { TranslationContext } from "../../assets/contexts/translationContext";
import { motion } from "framer-motion";

function Header({ language, setLanguage }) {
    const { theme, setTheme } = useContext(ThemeContext);
    const { header } = useContext(TranslationContext);
    const { headerLink } = header;
    const { aboutUs, prices, affiliateProgram, howToThriveInEcomm } = headerLink;

    const scrollDirection = useScrollDirection();
    const [isHeaderPopupOpen, setHeaderPopupOpen] = useState(false);
    const [isOpenDropDown, setDropdownOpen] = useState(false);
    const { pathname } = useLocation();
    const { width } = useWindowSize();
    const [searchParams, setSearchParams] = useSearchParams();
    const lang = searchParams.get("lang");

    function selectLanguage(lang) {
        // localStorage.setItem("language", lang);
        // setLanguage(lang);
        setSearchParams(params => {
            params.set("lang", lang);
            return params;
        });
        setDropdownOpen(!isOpenDropDown)
        // if (isDropdownOpen[DROPDOWN_LANG]) closeDropdown(DROPDOWN_LANG);
    }

    const HEADER_LINKS = [
        // {
        //     path: '/',
        //     name: 'Home'
        // },
        {
            path: '/about',
            name: `${aboutUs.name}`
        },
        {
            path: '/prices',
            name: `${prices.name}`
        },
        {
            path: '/partners',
            name: `${affiliateProgram.name}`
        },
        {
            path: '/blog',
            name: `${howToThriveInEcomm.name}`
        },
    ]

    return (
        <header className={`header ${scrollDirection === "down" ? "header_hide" : "header_show"}`} style={{ '--logo-position': width > 1920 ? `${(width - 1920) / 2 + 80}px` : '80px' }}>
            <div className='header__content'>
                <Link className='header__logo-box' to={'/'}>
                    <LogoIcon
                        mainClassName={`header__logo ${pathname === '/' && width > 1200 ? 'header__logo_fixed' : ''}`}
                    />
                </Link>
                <div className='header__links'>
                    {HEADER_LINKS.map((link, i) => (
                        <NavLink
                            key={`header__link_${i}`}
                            to={link.path}
                            className={({ isActive }) =>
                                `header__link ${isActive ? "header__link_active" : ""
                                } ${link.disabled ? "header__link_disabled" : ""}`
                            }
                        >
                            {link.name}
                        </NavLink>
                    ))}
                </div>
                <div className='header__controls'>
                    <a className='header__login' href='https://ro.sellavi.com/register' target='_blank' rel='noreferrer'>{header.signIn}</a>
                    <button className='header__control' onClick={() => {
                        setTheme(theme === THEME_LIGHT_TYPE ? THEME_DARK_TYPE : THEME_LIGHT_TYPE)
                    }}>
                        <SunIcon
                            mainClassName={'header__control-icon'}
                            fillClassName={'header__control-icon-fill'}
                        />
                    </button>
                    <button className='header__control-mobile-menu' type='button' onClick={() => {
                        setHeaderPopupOpen(prevValue => !prevValue)
                    }}>
                        <MobileMenuIcon
                            mainClassName={'header__control-mobile-menu-icon'}
                            strokeClassName={'header__control-mobile-menu-icon-stroke'}
                        />
                    </button>
                    <button onClick={() => setDropdownOpen(!isOpenDropDown)} className='header__control-language header__control-language_desktop'>
                        {language == EN_LANGUAGE ?
                            LANGUAGES.EN.flag :
                            language == RO_LANGUAGE ?
                                LANGUAGES.RO.flag :
                                LANGUAGES.RO.flag}

                        {language == EN_LANGUAGE ?
                            LANGUAGES.EN.lang :
                            language == RO_LANGUAGE ?
                                LANGUAGES.RO.lang :
                                LANGUAGES.RO.lang}
                    </button>

                    {isOpenDropDown ?
                        <motion.div
                            className='header__dropdown-language header__control-language_desktop'
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                        >
                            <button onClick={() => selectLanguage(EN_LANGUAGE)} className={`header__control-language ${language === EN_LANGUAGE ? 'header__control-language_selected' : ''}`}>
                                {LANGUAGES.EN.flag}
                                {LANGUAGES.EN.lang}
                            </button>
                            <div onClick={() => selectLanguage(RO_LANGUAGE)} className={`header__control-language ${language === RO_LANGUAGE ? 'header__control-language_selected' : ''}`}>
                                {LANGUAGES.RO.flag}
                                {LANGUAGES.RO.lang}
                            </div>
                        </motion.div>
                        : null}
                </div>
            </div>
            <HeaderPopup
                language={language}
                setLanguage={setLanguage}
                isHeaderPopupOpen={isHeaderPopupOpen}
                setHeaderPopupOpen={setHeaderPopupOpen}
            />
        </header>
    );
}

export default Header