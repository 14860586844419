import './HeaderBannerAboutUs.css';
import phones from '../../../assets/images/aboutUs/phones.webp'
import phonesMobile from '../../../assets/images/aboutUs/phones-mobile.webp'
import { motion } from 'framer-motion'
import useWindowSize from '../../../assets/hooks/useWindowSize';
import { TranslationContext } from '../../../assets/contexts/translationContext';
import { useContext } from 'react';

function HeaderBannerAboutUs() {
  const window = useWindowSize()
  const { aboutUs } = useContext(TranslationContext);
  
  return (
    <motion.div
      className='header-banner-about-us'>
      <p className='header-banner-about-us__title'>
        <span className='header-banner-about-us__title_green'>{aboutUs.mainTitleNumber}</span>
        {aboutUs.mainTitle}</p>
      <p className='header-banner-about-us__subtitle'>{aboutUs.mainText} <br />{aboutUs.mainTextLastLine}</p>
      <motion.img
        initial={{ x: '100%' }}
        animate={{ x: '0%' }}
        transition={{ duration: 3 }}
        alt='' src={window.width >= 800 ? phones : phonesMobile} className='header-banner-about-us__img'></motion.img>
    </motion.div>
  );
}

export default HeaderBannerAboutUs