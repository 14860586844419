import { useEffect, useRef } from "react";
import { useAnimation, useInView, motion } from "framer-motion";
import { StarIcon } from "../../../../assets/icons/icons";
import "./ReviewSlide.css";

const animationDelayIncrement = 0.15;
const animationSpeed = 0.5;

function ReviewSlide({ slide, theme, index }) {
  const slideRef = useRef();
  const slideInView = useInView(slideRef, { amount: 0.5 });
  const slideControls = useAnimation();

  useEffect(() => {
    slideInView
      ? slideControls.start("visible")
      : slideControls.start("hidden");
  }, [slideInView, slideControls]);

  return (
    <motion.div
      className="review-slide"
      ref={slideRef}
      initial="hidden"
      animate={slideControls}
    >
      <div className="review-slide__info">
        <div className="review-slide__info-head">
          <div className="review-slide__info-avatar">
            <img
              className="review-slide__info-avatar-logo"
              src={slide.author.img}
              alt=""
            />
          </div>
          <div className="review-slide__info-names">
            <p className="review-slide__info-name">{slide.author.name}</p>
            <div className="review-slide__info-stars">
              {Array(5)
                .fill("")
                .map((item, i) => (
                  <StarIcon
                    mainClassName="review-slide__info-star"
                    fillClassName="review-slide__info-star-fill"
                    key={`review-star-icon-${i}`}
                  />
                ))}
            </div>
            <p className="review-slide__info-site">{slide.author.site}</p>
          </div>
        </div>
        <p className="review-slide__description">{slide.description}</p>
        <img
          className="review-slide__logo"
          src={
            theme === "dark" ? slide.author.logo : slide.author.logo_theme_white
          }
          alt=""
        />
      </div>

      <div
        className={`review-slide__img-box review-slide__img-box_type_${index}`}
      >
        <motion.img
          className="review-slide__img-pc"
          src={slide.img_pc}
          variants={{
            hidden: { opacity: 0, x: "100%" },
            visible: {
              opacity: 1,
              x: 0,
              transition: {
                duration: animationSpeed,
                delay: animationDelayIncrement, // Offset starting delay for item animations
                ease: "easeInOut",
              },
            },
          }}
          alt=""
        />
        <motion.img
          className={`review-slide__img-mobile review-slide__img-mobile_${index}`}
          src={slide.img_mobile}
          variants={{
            hidden: { opacity: 0, x: "350%" },
            visible: {
              opacity: 1,
              x: 0,
              transition: {
                duration: animationSpeed,
                ease: "easeInOut",
              },
            },
          }}
          alt=""
        />
      </div>
    </motion.div>
  );
}

export default ReviewSlide;
