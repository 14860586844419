import useWindowSize from '../../../assets/hooks/useWindowSize';
import { PlusPlanIcon, PlusPointIcon, StandartPlanIcon, TickKeyPointIcon } from '../../../assets/icons/price';
import './ChoosePlan.css';

import lk from '../../../assets/images/marketing/lk.webp'
import { useContext } from 'react';
import { TranslationContext } from '../../../assets/contexts/translationContext';

function ChoosePlan({ onSignupOpen }) {
    const { prices } = useContext(TranslationContext);
    const { choosePlan } = prices;
    const { standartPlan, plusPlan } = choosePlan;
    const { keyPoints } = standartPlan;
    const { keyPointsPlus } = plusPlan;

    const { width } = useWindowSize()

    const STANDART_PLAN = {
        icon: StandartPlanIcon({ mainClassName: 'choose-plan__tarrif-icon' }),
        price: {
            value: `${standartPlan.priceNumber}`,
            currency: `${standartPlan.priceCurrency}`,
        },
        description: `${standartPlan.description}`,
        key_points: [
            {
                value: `${keyPoints.point1}`,
            },
            {
                value: `${keyPoints.point2}`,
            },
            {
                value: `${keyPoints.point3}`,
            },
            {
                value: `${keyPoints.point4}`,
            },
            {
                value: `${keyPoints.point5}`,
            },
            {
                value: `${keyPoints.point6}`,
            },
            {
                value: `${keyPoints.point7}`,
            },
            {
                value: `${keyPoints.point8}`,
            },
            {
                value: `${keyPoints.point9}`,
            },
            {
                value: `${keyPoints.point10}`,
            },
            {
                value: `${keyPoints.point11}`,
            },
            {
                value: `${keyPoints.point12}`,
            },
            {
                value: `${keyPoints.point13}`,
            },
            {
                value: `${keyPoints.point14}`,
            },
            {
                value: `${keyPoints.point15}`,
            },
            {
                value: `${keyPoints.point16}`,
            },
            {
                value: `${keyPoints.point17}`,
            },
            {
                value: `${keyPoints.point18}`,
            },
            {
                value: `${keyPoints.point19}`,
            },
        ],
    }
    const PLUS_PLAN = {
        icon: PlusPlanIcon({ mainClassName: 'choose-plan__tarrif-icon choose-plan__tarrif-icon_plus' }),
        price: {
            value: `${plusPlan.priceNumber}`,
            currency: `${plusPlan.priceCurrency}`,
        },
        description: `${plusPlan.description}`,
        plus_points: [
            {
                value: `${keyPointsPlus.point1}`,
            },
            {
                value: `${keyPointsPlus.point2}`,
            },
            {
                value: `${keyPointsPlus.point3}`,
            },
        ],
    }

    const STANDART_PLAN_KEY_POINTS = width > 880 ? STANDART_PLAN.key_points.slice(0, 7) : STANDART_PLAN.key_points
    return (
        <div className='choose-plan'>
            <h4 className='choose-plan__title'>{choosePlan.title}</h4>
            <div className='choose-plan__content'>
                <div className='choose-plan__tarrifs'>
                    <div className='choose-plan__tarrif'>
                        {STANDART_PLAN.icon}
                        <div className='choose-plan__tarrif-price-box'>
                            <p className='choose-plan__tarrif-price-value'>{STANDART_PLAN.price.value}</p>
                            <div className='choose-plan__tarrif-price-cur-and-period'>
                                <p className='choose-plan__tarrif-price-currency'>{STANDART_PLAN.price.currency}</p>
                                <p className='choose-plan__tarrif-price-period'>/{choosePlan.pricePer}*</p>
                            </div>
                        </div>
                        <button className='choose-plan__tarrif-start' type='button' onClick={onSignupOpen}>
                            {choosePlan.trialBtn}
                        </button>
                        <p className='choose-plan__tarrif-description'
                            dangerouslySetInnerHTML={{
                                __html: STANDART_PLAN.description,
                            }}
                        ></p>
                        <p className='choose-plan__tarrif-hint'>*{standartPlan.keyPointStar}</p>
                        <div className='choose-plan__tarrif-key-points'>
                            {STANDART_PLAN_KEY_POINTS.map((item, i) => (
                                <div className='choose-plan__tarrif-key-point' key={`choose-plan__tarrif-key-point_standart_${i}`}>
                                    <TickKeyPointIcon mainClassName={'choose-plan__tarrif-key-point-tick'} />
                                    <p className='choose-plan__tarrif-key-point-value'>{item.value}</p>
                                </div>
                            ))}
                            <div className='choose-plan__tarrif-key-point'>
                                <TickKeyPointIcon mainClassName={'choose-plan__tarrif-key-point-tick'} />
                                <p className='choose-plan__tarrif-key-point-value choose-plan__tarrif-key-point-value_final' >{standartPlan.keyPointLast}</p>
                            </div>
                        </div>
                        {/* <button className='choose-plan__tarrif-compare' type='button'>
                            Compare all features
                        </button> */}
                    </div>
                    <div className='choose-plan__tarrif choose-plan__tarrif_plus'>
                        {PLUS_PLAN.icon}
                        <div className='choose-plan__tarrif-price-box'>
                            <p className='choose-plan__tarrif-price-value'>{PLUS_PLAN.price.value}</p>
                            <div className='choose-plan__tarrif-price-cur-and-period'>
                                <p className='choose-plan__tarrif-price-currency'>{PLUS_PLAN.price.currency}</p>
                                <p className='choose-plan__tarrif-price-period'>/{choosePlan.pricePer}*</p>
                            </div>
                        </div>
                        <button className='choose-plan__tarrif-start' type='button' onClick={onSignupOpen}>
                            {choosePlan.trialBtn}
                        </button>
                        <p className='choose-plan__tarrif-description'
                            dangerouslySetInnerHTML={{
                                __html: PLUS_PLAN.description,
                            }}
                        ></p>
                        <p className='choose-plan__tarrif-hint'>*{standartPlan.keyPointStar}</p>
                        <div className='choose-plan__tarrif-key-points'>
                            <p className='choose-plan__tarrif-key-points-title'>{keyPointsPlus.title}</p>
                            {PLUS_PLAN.plus_points.map((item, i) => (
                                <div className='choose-plan__tarrif-key-point' key={`choose-plan__tarrif-plus-point_plus_${i}`}>
                                    <PlusPointIcon mainClassName={'choose-plan__tarrif-key-point-tick'} />
                                    <p className='choose-plan__tarrif-key-point-value'>{item.value}</p>
                                </div>
                            ))}
                        </div>
                        {/* <button className='choose-plan__tarrif-compare' type='button'>
                            Compare all features
                        </button> */}
                    </div>
                </div>
                <img className='choose-plan__img' src={lk} alt='' />
            </div>
        </div>
    );
}

export default ChoosePlan