import './OurPartners.css';

import master from '../../../assets/images/aboutUs/mastercard.svg'
import maestro from '../../../assets/images/aboutUs/maestro.svg'
import maestroBlack from '../../../assets/images/aboutUs/maestroBlack.svg'
import tiktok from '../../../assets/images/aboutUs/tiktok.svg'
import tiktokBlack from '../../../assets/images/aboutUs/tiktokBlack.svg'
import insta from '../../../assets/images/aboutUs/insta.svg'
import instaBlack from '../../../assets/images/aboutUs/instaBlack.svg'
import tg from '../../../assets/images/aboutUs/tg.svg'
import tgBlack from '../../../assets/images/aboutUs/tgBlack.svg'
import visa from '../../../assets/images/aboutUs/visa.svg'
import google from '../../../assets/images/aboutUs/google.svg'
import fb from '../../../assets/images/aboutUs/fb.svg'
import whap from '../../../assets/images/aboutUs/whap.svg'
import whapBlack from '../../../assets/images/aboutUs/whapBlack.svg'
import magnati from '../../../assets/images/aboutUs/magnati.svg'
import masterBlack from '../../../assets/images/aboutUs/masterBlack.svg'
import aramex from '../../../assets/images/aboutUs/aramex.svg'
import bulbank from '../../../assets/images/aboutUs/bulbank.webp'
import bulbankBlack from '../../../assets/images/aboutUs/bulbankBlack.webp'
import fan from '../../../assets/images/aboutUs/fan.webp'

import { ThemeContext } from "../../../assets/contexts/themeContext";
import { THEME_LIGHT_TYPE } from "../../../assets/utils/constants";
import { useContext } from 'react';
import { TranslationContext } from '../../../assets/contexts/translationContext';

function OurPartners() {
    const { theme } = useContext(ThemeContext);
    const { aboutUs } = useContext(TranslationContext);
    const { ourPartners } = aboutUs;

    const OURPARTNERS = [
        {
            img: master,
            blackImg: masterBlack
        },
        {
            img: bulbank,
            blackImg: bulbankBlack
        },
        {
            img: visa,
            blackImg: visa
        },
        {
            img: tiktok,
            blackImg: tiktokBlack,
        },
        {
            img: google,
            blackImg: google
        },
        {
            img: insta,
            blackImg: instaBlack,
        },
        {
            img: tg,
            blackImg: tgBlack,
        },
        {
            img: whap,
            blackImg: whapBlack,
        },
        {
            img: fb,
            blackImg: fb
        },
        {
            img: maestro,
            blackImg: maestroBlack,
        },
        {
            img: fan,
            blackImg: fan
        },
    ]

    return (
        <div className='partners'>
            <p className='partners__title'>{ourPartners.title}</p>

            <div className='partners__logo_line-1'>
                {OURPARTNERS.map((item, i) => {
                    return (
                        // <item.img style={{gridArea: `card${i+1}`}} mainClassName={`partners__logo_line-1__logo partners__logo_line-1__logo_card${i+1}`} key={`partners__logo_line-1__logo_card${i+1}`} />
                        <img style={{ gridArea: `card${i + 1}` }} alt='' src={theme === THEME_LIGHT_TYPE ? item.img : item.blackImg} className={`partners__logo_line-1__logo partners__logo_line-1__logo_card${i + 1}`} key={`partners__logo_line-1__logo_card${i + 1}`}></img>
                    )
                })}
            </div>

        </div>
    );
}

export default OurPartners