import invite from "../../../assets/images/partners/overview/invite-img.webp";
import coupon from "../../../assets/images/partners/overview/coupon-img.webp";
import commission from "../../../assets/images/partners/overview/commission-img.webp";
import "./PartnersOverview.css";
import { useContext } from "react";
import { TranslationContext } from "../../../assets/contexts/translationContext";

function PartnersOverview() {
  const { affiliateProgram } = useContext(TranslationContext);
  const { overview } = affiliateProgram;
  const { cardsMakeMoney } = overview;

  const cards = [
    {
      title: `${cardsMakeMoney.card1}`,
      img: invite,
    },
    {
      title: `${cardsMakeMoney.card2}`,
      img: coupon,
    },
    {
      title: `${cardsMakeMoney.card3}`,
      img: commission,
    },
  ];


  return (
    <div className="partners-overview">
      <p className="partners-overview__title">{overview.title}</p>
      <p className="partners-overview__text">
        {overview.textFirst}
      </p>
      <p className="partners-overview__text">
        {overview.textSecond}
      </p>

      <div className="partners-overview__cards-block">
        <p className="partners-overview__title">
          {overview.titleSecond}
        </p>
        <ul className="partners-overview__list">
          {cards.map((item, i) => (
            <li className="partners-overview__item" key={`overview-${i}`}>
              <img
                className="partners-overview__card-img"
                src={item.img}
                alt=""
              />
              <p className="partners-overview__card-text">{item.title}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default PartnersOverview;
